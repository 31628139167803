const baseUrl = "http://198.211.110.165:9000"
// const baseUrl = "http://192.168.2.21:9000"
// const baseUrl = "http://192.168.2.124:9000"
const botsBaseURL = "https://108.175.2.99"


let maxFileSize = 1001000;                              //1mb
let imageType = ['image/jpg', 'image/jpeg', 'image/png'];
let allowedSymbol = ['BTC', 'ETH', 'BNB']


export const config = {
    apiUrl: baseUrl + "/api/v1",
    imageUrl: baseUrl + '/',
    botsApiUrl: botsBaseURL + "/v1",
    minBatchTxns:2
}



export const allowableNetworks = [
    {
        networkName: 'Ropsten Test Network',
        chainId: 3,
    },
    {
        networkName: 'Smart Chain - Testnet',
        chainId: 97,
    },
]

export const validations = {
    nameLength: 30,
    nameRegEx: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
    allowedSymbol,
    desLength: 500,
    additionalInfo: 200,
    urlRegEx: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    validImage: (file) => {
        if (imageType.includes(file.type)) {
            if (file.size >= maxFileSize) {
                return { msg: 'BOT_IMAGE_SIZE', status: false };
            } else {
                return { msg: '', status: true }
            }
        }
        else {
            return { msg: 'BOT_IMAGE_TYPE', status: false };
        }
    }
}


// cat list popular skate elbow repeat magic erode early flip mail cruel