import { useEffect } from "react"
import { useHistory } from "react-router-dom"


const NotFound = () => {
    const history = useHistory()
    useEffect(() => {
        history.push("/")
    }, [])
    return <>
        <h1>Page not found</h1>
    </>
}

export default NotFound