import { config } from "../config/config";
import axios from "axios";
import { formDataHeaders, jsonHeaders, jsonHeadersBots } from "../helpers";
// import { param } from "jquery";



var configFormDataHeaders = {
    headers: formDataHeaders(),

}


var configJsonHeaders = {
    headers: jsonHeaders()
}

var configJsonHeadersBots = {
    headers: jsonHeadersBots()
}

export const userServices = {
    getUser,
    getBots,
    getHashData,
    addHashData, createETHaccount, getUserTokens
}


function getUser(params) {
    let url = `${config.apiUrl}/user`;
    return axios.post(url, params, configJsonHeaders);
}

function getBots() {
    let url = `${config.apiUrl}/bots/`
    return axios.get(url, configJsonHeaders)
}

function getHashData(params) {
    let url = `${config.apiUrl}/user/address`;
    return axios.post(url, params);
}

function addHashData(params){
    let url = `${config.apiUrl}/user/hash`;
    return axios.post(url , params );
}

function createETHaccount(){
    let url = `${config.apiUrl}/transaction/createEthAccount`;
    return axios.get(url, configJsonHeaders)
}

function getUserTokens(params){
    let url = `${config.apiUrl}/user/getUserTokens`;
    return axios.post(url, params, {});
}