export const ToastMessage = (key, msgReceived = '') => {
    let msg = ''
    switch (key) {

        //Create
        case 'BOT_INVALID_NAME':
            msg = 'Please Enter Valid Name';
            break;

        case 'PAY_SUCCESS':
            msg = 'Payment Done Successfully';
            break;

        //Updations     


        //Deletion


        //Listing    

        //Wallet
        case 'CONNECT_SUCCESS':
            msg = 'Wallet Connected Successfully';
            break;
        case 'WALLET_DISCONNECT_SUCCESS':
            msg = 'Wallet Disconnected Successfully';
            break;
        case 'METAMASK_UNAVAILABLE':
            msg = "Please Add Metamask";
            break;

        case 'USER_REJECTED':
            msg = msgReceived;
            break;
        case 'USER_REJECTS':
            console.log(msgReceived)
            if (msgReceived.split(' ')[0] == 'MetaMask') {
                msg = msgReceived.split(':')[1];
            } else {
                msg = 'Insufficient Funds';
            }
            break;
        case `PLAN_PAYMENT_SUCCESS`:
            msg = 'Payment Successfull';
            break;
        case 'NETWORK_NOT_ALLOWED':
            msg = 'Payment Network Not Allowed';
            break;
        case 'WALLET_NOT_CONNECTED':
            msg = 'Please Connect Wallet';
            break;

        //Subscriber's Email
        case 'EMAIL_EMPTY':
            msg = 'Please Add Email';
            break;
        case 'EMAIL_INVALID':
            msg = 'Please Enter Valid Email';
            break;
        //Promoted Coins Submit

        case 'NAME_EMPTY':
            msg = 'Name Cannot Be Empty';
            break;
        case 'NAME_LENGTH':
            msg = 'Name Is Too Long (max : 30)';
            break;
        case 'NAME_INVALID':
            msg = 'Invalid Name';
            break;
        case 'SYMBOL_EMPTY':
            msg = 'Coin Symbol is Empty';
            break;
        case 'SYMBOL_LENGTH':
            msg = 'Symbol Length (max : 10)';
            break;
        case 'SYMBOL_INVALID':
            msg = 'Invalid Symbol';
            break;
        case 'COIN_SUBMITTED':
            msg = 'Coin Promoted Successfully';
            break;
        case 'DESC_LENGTH':
            msg = 'Description Is Too Long';
            break;
        case 'PRICE_EMPTY':
            msg = 'Price Cannot be Empty';
            break;
        case 'WEB_INVALID':
            msg = 'Invalid Website URL';
            break;
        case 'TELE_INVALID':
            msg = 'Invalid Telegram URL';
            break;
        case 'IMAGE_EMPTY':
            msg = 'Please Add An Image';
            break;
        case 'BOT_IMAGE_SIZE':
            msg = 'Image Size Exceeded (< 1 MB)';
            break;
        case 'BOT_IMAGE_TYPE':
            msg = 'Invalid Image Type';
            break;
        case 'ADDITIONAL_INFO_LENGTH':
            msg = 'Additional Infomartion Is Too Long (max: 200)';
            break;

        case 'LAUNCH_DATE_EMPTY':
            msg = 'Please Select A Date';
            break;
        case 'PREV_LAUNCH_DATE':
            msg = 'Previous Date Is Not Allowed'
            break;
        case 'MARKET_CAP_EMPTY':
            msg = 'Please Add Market Cap Value';
            break;

        //Cart 
        case 'PLAN_CART_SUCCESS':
            // msg = 'Plan Added To Successful'
            msg = 'Product Added Succesfully'
            break;
        case 'CART_DEL_SUCCESS':
            msg = 'Item Removed Successfully'
            break;

        //--------------------MyBots---------------------
        //DxPreSale
        case 'DXADD_EMPTY':
            msg = 'Please Add Address';
            break;
        case 'DXAMT_EMPTY':
            msg = 'Please Add Amount';
            break;
        case 'DXAMT_INVALID':
            msg = 'Invalid Amount';
            break;
        

        //---------------------PanCakeSwap-------------------
        //pancakeswap
        case 'PCONTRACT_EMPTY':
            msg='please Add Contract Address';
            break;
        case 'PAMOUNT_EMPTY':
            msg="please Add Amount"
            break;
        default:
            msg = "Some Error Occurred";
    }

    return msg;
}