import React, { useState, useEffect, } from 'react';
import { Modal } from "react-bootstrap";
import 'react-step-progress/dist/index.css';
import ReactTooltip from 'react-tooltip';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useMoralis } from "react-moralis";


const Stepthree = ({ Account_Balance, addressAmountList, totalAmount, setSteps, gweiFee, batchTxns, setGweiFee, gweiMaxPrice, setGweiMaxPrice, setLoader, network, setAmountWithFee, selectValue, symbol, decimals }) => {

    const { web3, enableWeb3, user, Moralis, isWeb3Enabled, isAuthenticated } = useMoralis();
    const [maxFee, SetMaxFee] = useState(3);
    const [minFee, setMinFee] = useState(1);
    const [show1, setShow1] = useState(false);
    const [totalTxnAmountEth, setTotalTxnAmountEth] = useState(0)

    const handleClose = () => {
        setShow1(false)
        setSteps({ step2: false, step3: false, step1: true })
    };


    useEffect(() => {
        setLoader(true)
        let estimatedPrice = 0
        if (batchTxns.length) {
            setGweiMaxPrice(batchTxns[0]?.gas)
            for (let index = 0; index < batchTxns.length; index++) {
                const element = batchTxns[index];
                if (selectValue.value != network) {
                    estimatedPrice += (Number(element.gasPrice) * Number(element.gas))
                    console.log('token estimated', estimatedPrice)
                } else {
                    estimatedPrice += ((Number(element.gasPrice) * Number(element.gas)) + Number(element.amount))
                    console.log('eth')
                }
                console.log(' estimateddd >> ', estimatedPrice)
            }
            let calculatedAmt = estimatedPrice
            let gweiGas = Number(Moralis.Units.FromWei(estimatedPrice))
            if (selectValue.value == network) {
                calculatedAmt = Number(Number(gweiGas + totalAmount))
            }
            console.log(' calculated >> ', calculatedAmt)
            // if(calculatedAmt > Account_Balance) {
            //     toast.error("Insufficient Balance for this transaction. Required "+calculatedAmt.toPrecision(6))
            //     return false
            // }
            setTotalTxnAmountEth(calculatedAmt)
            setAmountWithFee(calculatedAmt)
            setLoader(false)
        }
    }, [batchTxns])

    const gas_price = (e) => {
        setGweiMaxPrice(e);
    }

    const priority_fee = (e) => {
        setGweiFee(e);
    }


    return (
        <>
            {/* {console.log(network)} */}
            <section className='pt-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-10 col-lg-10 col-md-12 m-auto'>
                            <div className='multisender_page'>
                         
                                <div className='row align-items-center'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 m-auto p-0'>
                                        <div className='steptwo-area'>
                                            <div className='below_data'>
                                                <div className='data_row mb-2'>
                                                    <p className='mb-0'>Total number of addresses</p>
                                                    <p className='data_amount mb-0'>{addressAmountList.length}</p>
                                                </div>
                                                <div className='data_row mb-2'>
                                                    <p className='mb-0'>Total number of tokens to send</p>
                                                    <p className='data_amount mb-0'>{totalAmount} {symbol}</p>
                                                </div>
                                                <div className='data_row mb-2'>
                                                    <p className='mb-0'>Your token balance</p>
                                                    <p className='data_amount mb-0'>{Account_Balance} {symbol}</p>
                                                </div>

                                                <div className='data_row mb-2'>
                                                    <p className='mb-0'>Total number of transaction needed</p>
                                                    <p className='data_amount mb-0'>{batchTxns.length}</p>
                                                </div>

                                                <div className='data_row mb-2'>
                                                    <p className='mb-0'>Your  balance</p>
                                                    <p className='data_amount mb-0'>{Account_Balance} {symbol}</p>
                                                </div>
                                                <div className='data_row mb-2'>
                                                    <p className='mb-0'>Approximate cost of Airdrop (Total Gas Cost)</p>
                                                    <p className='data_amount mb-0'>{
                                                        selectValue.value == network ?
                                                            Number(Number(totalTxnAmountEth).toPrecision(5))
                                                            : Moralis.Units.FromWei(totalTxnAmountEth.toFixed(6))
                                                    } </p>
                                                </div>
                                            </div>

                                            <div className='row rangebar_row mt-5'>
                                                <div className='col-md-6 text-center'>
                                                    <h5 className='d-inline-block'>{gweiMaxPrice != 0 ? gweiMaxPrice : batchTxns[0] && batchTxns[0]?.gas ? batchTxns[0]?.gas : 0} Gwei
                                                        <img src={require('../../Images/info_white.svg').default} alt="" className='ms-2' data-tip="Higher= faster Tx" />
                                                        <ReactTooltip />
                                                    </h5>
                                                    {
                                                        batchTxns[0] && batchTxns[0]?.gas
                                                            ?
                                                            <Slider min={batchTxns[0]?.gas} max={batchTxns[0]?.gas + 1000} onChange={(value) => gas_price(value)} />
                                                            : ""
                                                    }
                                                    <p style={{ color: "#fff", fontSize: "16px" }} className="mt-2">Max Price</p>
                                                </div>

                                                {/* Priority Fee */}
                                                <div className='col-md-6 text-center'>
                                                    <h5 className='d-inline-block'>{gweiFee} Gwei
                                                        <img src={require('../../Images/info_white.svg').default} alt="" className='ms-2' data-tip="Higher= faster Tx" />
                                                        <ReactTooltip />
                                                    </h5>
                                                    <Slider disabled={network == 'PRIVATE' ? true : false} min={minFee} max={maxFee} defaultValue={2} onChange={(value) => { priority_fee(value) }} />
                                                    <p style={{ color: "#fff", fontSize: "16px" }} className="mt-2">Priority Fee</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='my-4 text-center'>
                                            <button className='upload_jsonbtn nextbtn ms-2' onClick={() => set_show(!show)}>
                                                Next
                                                <img src={require('../../Images/next_white.svg').default} alt="" className='ms-3' />
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={show1} onHide={() => handleClose()} aria-labelledby="contained-modal-title-vcenter"
                centered size="sm">
                <Modal.Body>
                    <div className='transaction_modal text-center py-4'>
                        <img src={require('../../Images/success.svg').default} alt="" style={{ width: "70px" }} />
                        <p>Transaction Successfull!</p>
                        <button className='btn text-white' onClick={() => handleClose()}>Okay</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Stepthree;