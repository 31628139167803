import { Container } from "react-bootstrap";
import Header from "./Header";

const Faq = () => {
    return (
        <>
            <Header />
            <section className="aboutus-area">
                <Container>
                    <div className="about-us-content-inner">
                        <h3>Frequently Asked Questions (FAQs)</h3>
                        <h5 className="mb-3">General Questions</h5>
                        <div className="features-content">
                            <b>What is TokenAirdrop?</b>
                            <p>TokenAirdrop is a platform that facilitates the bulk distribution of rewards, NFTs (Non-Fungible Tokens), and cryptocurrencies to multiple recipients with a single click. It simplifies the process of engaging and incentivizing communities or audiences by automating reward distributions.</p>
                        </div>
                        <div className="features-content">
                            <b>How does TokenAirdrop work?</b>
                            <p>TokenAirdrop allows users to upload a list of recipients via an Excel spreadsheet, specify the type and quantity of rewards (tokens or NFTs), and initiate the distribution process seamlessly. The platform automates the distribution to ensure that rewards are delivered accurately and efficiently to the intended recipients.
                            </p>
                        </div>
                        <div className="features-content">
                            <b>What types of rewards can be distributed using TokenAirdrop?
                            </b>
                            <p>You can distribute various types of rewards, including tokens (crypto assets) and NFTs (Non-Fungible Tokens), using TokenAirdrop. The platform supports customizable reward options to meet diverse distribution needs.
                            </p>
                        </div>
                        <h5 className="mb-3">Using TokenAirdrop</h5>
                        <div className="features-content">
                            <b>How do I distribute rewards using TokenAirdrop?</b>
                            <p>To distribute rewards:</p>
                            <ul>
                                <li>Prepare a list of recipients in an Excel spreadsheet with their wallet addresses and reward details.</li>
                                <li>Upload the spreadsheet to TokenAirdrop.</li>
                                <li>Specify the type and quantity of rewards you want to distribute.</li>
                                <li>Customize distribution settings (if needed).</li>
                                <li>Initiate the distribution process to send rewards to all listed recipients at once.</li>
                            </ul>
                        </div>
                        <div className="features-content">
                            <b>Can I customize reward distributions on TokenAirdrop?</b>
                            <p>Yes, TokenAirdrop offers customizable options such as specifying token types, quantities, distribution timing, and any special conditions for reward distributions. Users have flexibility in tailoring the distribution process according to their specific requirements.
                            </p>
                        </div>
                        <div className="features-content">
                            <b>Is TokenAirdrop secure?</b>
                            <p>TokenAirdrop leverages blockchain technology for secure and transparent reward distributions. All transactions are recorded on the blockchain, ensuring immutable records of reward allocations. The platform prioritizes security to protect user data and assets.
                            </p>
                        </div>
                        <h5 className="mb-3">Account and Technical Support</h5>
                        <div className="features-content">
                            <b>How do I sign up for TokenAirdrop?</b>
                            <p>You can sign up for TokenAirdrop by visiting our website and registering for an account. Simply follow the registration process to get started with distributing rewards efficiently.
                            </p>
                        </div>
                        <div className="features-content">
                            <b>What if I encounter technical issues or need assistance?</b>
                            <p>If you encounter any technical issues or require assistance while using TokenAirdrop, please reach out to our support team. We're here to help resolve any issues and ensure a smooth user experience.
                            </p>
                        </div>
                        <div className="features-content">
                            <b>Does TokenAirdrop integrate with other platforms or services?</b>
                            <p>TokenAirdrop is designed to be user-friendly and compatible with existing systems. While direct integrations may vary, our platform supports seamless integration with Excel for recipient list uploads, making it easy to incorporate into your workflow.
                            </p>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};
export default Faq;