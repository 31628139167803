import React, { useState, useEffect, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Alert, Form } from 'react-bootstrap';
import { errors, ethers } from "ethers";
import Web3 from "web3";
import { toast } from "react-toastify";
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/theme/material.css';
import 'codemirror/lib/codemirror.css';
import { userServices } from '../services/user.services';
import qs from 'qs'
import Papa from "papaparse";
import { CSVDownload, CSVLink } from "react-csv";
import { useMoralis } from "react-moralis";
import CreatableSelect from 'react-select/creatable';

const Stepthree = () => {
    const initialVals = {
        duplicates: [],
        matched: [],
        received: [],
        notReceived: [],
    }
    const { web3, enableWeb3, user, Moralis, isWeb3Enabled, isAuthenticated } = useMoralis();
    // var rinky_provider = "https://rinkeby.infura.io/v3/162f2b69e8eb48a2bcd15dca05a3b996";
    var rinky_provider = "https://sepolia.infura.io/v3/94802db6ed4a4985902ebf2f47173b0e";
    // var Ropsten_provider = "https://ropsten.infura.io/v3/162f2b69e8eb48a2bcd15dca05a3b996";
    var Ropsten_provider = "https://sepolia.infura.io/v3/94802db6ed4a4985902ebf2f47173b0e";
    var bsc_testnet_provider = "https://data-seed-prebsc-1-s1.binance.org:8545";
    // const bsc_testnet = "0x6e336ed1549309dC43C18689815D4861757e65A5";
    // const rinkeby_test_net = "0x37f7026AC5eEc5293dbc2d702866854125e5d9af";
    // const Ropsten = "0x31C073290A18E71CeaE760Bbcaca9EC6a0853135";
    // var rink_id = 4;
    const bsc_testnet = "0x93b61C74f4EEF6F6bD5877C0FaE69e80314a4DE6";
    const rinkeby_test_net = "0xEbc527d74CE3f702F85D2E50BF7c3EAdB820d561";
    const Ropsten = "0xEbc527d74CE3f702F85D2E50BF7c3EAdB820d561";
    var rink_id = 11155111;
    var bsc_id = 97;
    // var ropsten_id = 3;
    var ropsten_id = 11155111;
    let abi = require('./MultisenderAbi.json');
    const [contractAddress, setContractAddress] = useState("");
    const [httpprovider, setHttpprovider] = useState("");
    const [loader, setLoader] = useState(false);
    const [hashList, setHashList] = useState("")
    const [recipientList, setRecipientList] = useState("")
    const [errors, setErrors] = useState([])
    const [duplicateAddresses, setDuplicateAddresses] = useState([])
    const [mergeDiv, showMergeDiv] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [result, setResult] = useState(initialVals)
    const [metaNetwork, setMetaNetwork] = useState("")
    const [selectValue, setSelectValue] = useState("")
    const [inputValue, setInputValue] = useState("")
    const recpEditor = useRef()
    const hashEditor = useRef()
    let modeLoaded = false
    const csvHashSample = [
        ["0x5e04b948e325b0e3e995d26f85a014cc2e4b48bef2f9fa75b9ed1bbad37f74f8"],
        ["0x05c63e99aa869ee5c89314b9e6d21775ad054391538dcb86fa842beaa84f12e5"],
        ["0x625553efa9d8aa9009534de834d09fbb1de9cf7f792d304086f0015d5ca56e5d"],
        ["0xc80b1b56d8eea570d858d5adc9d45114286c49568442e834e8bd37e322cd35fb"],
        ["0x78f3b06f3adca409fe066ef63f4d68dc484190d26898ecbf627bcf72a9ee6c27"],
    ]
    const csvDataSample = [
        ["0x82d244a267b53e0a73e552e5883ab7a5eb16ac2c", "0.0001"],
        ["0x695bc3d9fb940bc3e0b29a18dcd6b633c920dab2", "0.0001"],
        ["0xff97e76d6d47d8580be26f5e5fffdca2d16bd5dd", "0.0001"],
        ["0xde59e859bc0bf6fdb3bf5caf034dde9950be1277", "0.0001"],
        ["0xa4c63dc6ab5502525e742767309c281e6be2a043", "0.0001"],
    ]

    const upload_address = (e) => {
        if (e.target.files[0].type !== 'text/csv') {
            toast.error('Must upload csv file')
        } else {
            Papa.parse(e.target.files[0], {
                complete: function (results) {
                    let editorData = results.data.filter(function (val) {
                        return val[0].trim() != 'address'
                    }).join("\n")
                    setRecipientList(editorData);
                }
            }
            )
        }
    }

    if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
        require('react-codemirror2')
        modeLoaded = true
    }
    useEffect(() => {
        check_chain_id()
        HandleNetwork()
    }, [recpEditor, hashEditor])

    const check_chain_id = async () => {
        await Moralis.enableWeb3();
        const chainIdCurrent = Moralis.getChainId();
        Promise.resolve(chainIdCurrent).then(function (chain) {
            if (chain != rink_id) {
                if (chain != bsc_id) {
                    if (chain != ropsten_id) {
                        console.log('FALSE')
                    }
                    else {
                        setContractAddress(Ropsten);
                        setHttpprovider(Ropsten_provider);
                    }
                }
                else {
                    setContractAddress(bsc_testnet);
                    setHttpprovider(bsc_testnet_provider);
                }
            }
            else {
                setContractAddress(rinkeby_test_net);
                setHttpprovider(rinky_provider);
            }

        })
    }

    async function HandleNetwork() {
        if (window.ethereum) {
            let networkAdress = await window.ethereum.selectedAddress;
            var web3 = new Web3(window.ethereum);
            web3.eth.net.getNetworkType(function (error, result) {
                setMetaNetwork(result.toUpperCase())
            })
        } else {
            toast.error("No crypto wallet found. Please install it. Not Connected")

        }
    }

    const upload_hash = (e) => {
        if (e.target.files[0].type !== 'text/csv') {
            toast.error('Must upload csv file')
        } else {
            Papa.parse(e.target.files[0], {
                complete: function (results) {
                    setHashList(results.data.join("\n"))
                }
            }
            )
        }
    }

    function verifyAirDrop() {
        setLoader(true)
        setTimeout(() => {
            proceedVerify()
        }, 1500);
    }

    function proceedVerify() {
        setShowInfo(false)
        let hashes = hashList.split("\n")
        let address = recipientList.split("\n")
        let success = true
        let err = []
        let tempAddresses = []
        let duplicates = []
        if (hashes.length == 1 && hashes[0].trim() == '') {
            err.push("Please enter the transactions that you sent via Airdrop.")
            success = false
        }
        if (address.length) {
            if (address.length == 1 && address[0].trim() == '') {
                err.push("Please provide a corresponding amount for each address.")
                success = false
            } else {
                recpEditor.current.editor.doc.getAllMarks().forEach(marker => marker.clear());
                address.forEach((line, indexAddress) => {
                    if (line.trim() != '') {
                        let data = line.split(',')
                        let dataLength = data.toString().replaceAll(",", "").length
                        if (data.length != 2) {
                            success = false
                            if (data.length > 2) {
                                err.push(`Line ${indexAddress + 1}: Too many columns within the line.`)
                                recpEditor.current.editor.doc.markText({ line: indexAddress, ch: 0 }, { line: indexAddress, dataLength }, { className: "text-danger" })
                            } else if (data.length == 1) {
                                err.push("Invalid Record Length: expect 2, got 1 on Line " + (indexAddress + 1))
                                recpEditor.current.editor.doc.markText({ line: indexAddress, ch: 0 }, { line: indexAddress, dataLength }, { className: "text-danger" })
                            }

                        } else if (data[1].trim() == '' || isNaN(data[1])) {
                            success = false
                            err.push(`Line ${indexAddress + 1}: Wrong amount.`)
                            recpEditor.current.editor.doc.markText({ line: indexAddress, ch: 0 }, { line: indexAddress, dataLength }, { className: "text-danger" })
                        } else {
                            if (!tempAddresses.includes(data[0])) {
                                tempAddresses.push(data[0])
                            } else {
                                duplicates.push(indexAddress)
                            }
                        }
                    }
                });
                if (duplicates.length && success) {
                    success = false
                    showMergeDiv(true)
                    setDuplicateAddresses(duplicates)
                    duplicates.forEach((element, indexDuplicate) => {
                        err.push(`Line ${element + 1}: Duplicate address.`)
                        let lineLength = (recipientList.split("\n")[element].replaceAll(",", "").length) + 1
                        recpEditor.current.editor.doc.markText({ line: element, ch: 0 }, { line: element, ch: lineLength }, { className: "text-danger" })
                    });
                }
            }
        }
        setErrors(err)
        if (err.length) {
            setLoader(false)
        }
        if (success) {
            validateTxn()
        }
    }

    function mergeDuplicateRecords() {
        let address = recipientList.split("\n")
        let vals = address.filter((val, index) => duplicateAddresses.indexOf(index) == -1).filter(val => val != '')
        recpEditor.current.editor.setValue(vals.join("\n"))
        showMergeDiv(false)
        validateTxn()
    }

    function validateTxn() {
        hashEditor.current.editor.doc.getAllMarks().forEach(marker => marker.clear());
        recpEditor.current.editor.doc.getAllMarks().forEach(marker => marker.clear());
        let hashdata = hashEditor.current.editor.getValue().split("\n").filter(val => val != '')
        let recpData = recpEditor.current.editor.getValue().split("\n").filter(val => val != '')
        let inputVals = recpData.filter((val, index) => duplicateAddresses.indexOf(index) == -1).filter(val => val != '').map(function (add_amt, i) {
            let finalVal = add_amt.split(',')
            return [finalVal[0], Number(finalVal[1]).toPrecision(10)].toString()
        })
        setLoader(true)
        userServices.getHashData({ hash: hashdata, contract_address: selectValue.value == metaNetwork ? contractAddress : selectValue.value }).then(resp => {
            // setLoader(false)
            setTimeout(() => {
                abc(resp, hashdata, inputVals)
            }, 1000);
        }).catch(err => {
            setLoader(false)
            toast.dismiss()
            toast.error("Something went wrong.")
        })
    }

    function abc(resp, hashdata, inputVals) {
        let err = []
        let inputAddress = recipientList.split("\n").map(function (addAmtStr) {
            let addAmt = addAmtStr.split(',')
            return [addAmt[0], Number(Number(addAmt[1]).toPrecision(10))]
        })
        let tempDup = [["address", "amount"]], tempMatch = [["address", "amount"]], tempRecv = [["address", "amount"]], tempNRecv = [["address", "amount"]]
        let verifyResp = resp.data.data
        let botHashTxns = verifyResp.map((data) => data.hash)
        // let botTxns = verifyResp[0].address.map((data, index) => [data, Number(verifyResp[0].price[index])])
        let botTxns = []
        for (let dataIndex = 0; dataIndex < verifyResp.length; dataIndex++) {
            const arr = verifyResp[dataIndex];
            for (let index = 0; index < arr.address.length; index++) {
                const element = arr.address[index];
                // console.log([element, Number(arr.price[index])])
                botTxns.push([element, Number(arr.price[index])])
            }
        }
        let outsideTxn = hashdata.filter((val) => !botHashTxns.includes(val.trim()))
        if (outsideTxn.length) {
            hashdata.forEach((line, indexAddress) => {
                let data = line.split(',')
                let dataLength = data.toString().replaceAll(",", "").length
                if (outsideTxn.includes(line.trim())) {
                    hashEditor.current.editor.doc.markText({ line: indexAddress, ch: 0 }, { line: indexAddress, dataLength }, { className: "text-danger" })
                    err.push(`Line ${indexAddress + 1}: Please check whether this transaction was made for multisend.`)
                }

            });
        } else if (verifyResp.length && outsideTxn.length == 0) {
            let inputMatchIndexes = []
            let matchedVals = []
            for (let j = 0; j < botTxns.length; j++) { // api resp
                const txn = botTxns[j];
                tempRecv.push(txn) // recv
                let strAddAmt = txn.toString()
                if (matchedVals.includes(strAddAmt)) {
                    tempDup.push(txn) // duplicate
                }
                matchedVals.push(strAddAmt)
                for (let i = 0; i < inputVals.length; i++) { // input address, amount
                    const value = inputVals[i].split(',')
                    let val = [value[0], Number(Number(value[1]).toPrecision(10))]
                    let strVal = val.toString()
                    if (strAddAmt == strVal) { // match
                        tempMatch.push(txn)
                        inputMatchIndexes.push(j)
                    }
                }
            }
            for (let index = 0; index < inputVals.length; index++) {
                const value = inputVals[index].split(',')
                let val = [value[0], Number(Number(value[1]).toPrecision(10))]
                if (!inputMatchIndexes.includes(index)) {
                    tempNRecv.push(val) // not received
                }
            }
            setResult({ duplicates: tempDup, matched: tempMatch, received: tempRecv, notReceived: tempNRecv })
            setShowInfo(true)
        } else {
            err.push("Please check whether transactions were made for multisend.")
        }
        setErrors(err)
        setLoader(false)
    }

    return (
        <>
            {
                loader ?
                    <div className='main_loader'>
                        <div className="loader"></div>
                    </div>
                    :
                    <></>
            }
            <Header />
            <div className='stepper_page'>
                <div className='multi_sender_stepper'>

                    <section className='pt-3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-xl-10 col-lg-11 col-md-12 m-auto'>
                                    <div className='multisender_page airdrop-verification-area'>
                                        {/* <div className='row align-items-center'>
                                            <div className='col-lg-9 col-md-10 col-sm-12 m-auto text-center'>
                                                <div className='py-3'>
                                                    <h2>Airdrop Verification</h2>
                                                    <p className='my-3 text-white'>If you have a list of recipients and a list of transactions from your previous multi-send, you can check which recipients received their tokens.</p>

                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='row align-items-center'>
                                            <div className='col-lg-9 col-md-10 col-sm-12 m-auto p-0'>
                                                <div className='outer-box-area mt-0'>
                                                    <div className='py-3 pt-0 text-center'>
                                                        <h2><span>Airdrop Verification</span></h2>
                                                        <p className='my-3 text-white'>If you have a list of recipients and a list of transactions from your previous multi-send, you can check which recipients received their tokens.</p>

                                                    </div>
                                                    <Form>
                                                        <div className='flex_frow' >
                                                            <Form.Group className="mb-3 " style={{ flex: "1" }} >
                                                                <Form.Label htmlFor="">Token Address</Form.Label>
                                                                <div className='position_relative '>
                                                                    <CreatableSelect
                                                                        styles={{
                                                                            control: (base, state) => ({
                                                                                // ...base,
                                                                                background: "hsl(37.75deg 74.17% 52.94% / 0%)",
                                                                                border: "1px solid #e09e2e5c",
                                                                                padding: "4px",
                                                                                borderRadius: "10px",
                                                                            }),
                                                                            input: (base, state) => ({
                                                                                ...base,
                                                                                color: "white"
                                                                            })

                                                                        }}
                                                                        placeholder="Select token"
                                                                        options={[{ value: metaNetwork, label: `${metaNetwork} Test Network` }]}
                                                                        inputValue={inputValue}
                                                                        onInputChange={setInputValue}
                                                                        value={selectValue}
                                                                        onChange={setSelectValue}
                                                                        onBlur={() => {
                                                                            if (inputValue) {
                                                                                setSelectValue(v => ({ label: inputValue, value: inputValue }))
                                                                            }
                                                                        }}
                                                                        components={{ DropdownIndicator: null }}
                                                                    />
                                                                    {/* <Form.Select id="">
                                                                    <option value={0}>{metaNetwork} Test Network</option>
                                                                </Form.Select>
                                                                <img src={require('../Images/arrow_down.svg').default} alt="" className='me-2 arrow_img' /> */}
                                                                </div>
                                                            </Form.Group>
                                                            {/* <div className=''>
                                                                <Form.Label htmlFor="" className='d-block'>Deflationary</Form.Label>
                                                                <Switch onChange={handleOnChange} checked={toggle} uncheckedIcon="Off" checkedIcon="On" className={toggle ? 'switch' : 'switchafter'}
                                                                    offColor="#0a4a7c"
                                                                    onColor="#4abaea"
                                                                    activeBoxShadow='0 0 2px 3px #3bf'
                                                                />
                                                            </div> */}
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <Form.Label htmlFor="">Your TX's hashes</Form.Label>
                                                            <CSVLink data={csvHashSample} filename="sampleHash.csv" className='download-csv-link'>Download Sample CSV</CSVLink>
                                                        </div>
                                                        <div className='textarea_row'>
                                                            {/* <div className='listing_div text-center'>
                                                                <p>1</p>
                                                            </div> */}
                                                            <Form.Group style={{ flex: "1" }}>
                                                                {modeLoaded && <CodeMirror
                                                                    value={hashList}
                                                                    options={{
                                                                        theme: "material",
                                                                        lineNumbers: true,
                                                                    }}
                                                                    ref={hashEditor}
                                                                    onBeforeChange={(editor, data, value) => {
                                                                        setHashList(value)
                                                                    }}
                                                                />}
                                                                {/* <Form.Control as="textarea" /> */}
                                                            </Form.Group>
                                                        </div>
                                                        <div className="upload_input_1 my-3">
                                                            <label className="upload_jsonbtn me-2" htmlFor="react-csv-reader-input1">Upload CSV</label>
                                                            <div className="react-csv-input">
                                                                <input className="csv-input" type="file" id="react-csv-reader-input1" name="react-csv-reader-input" onClick={(e) => e.target.value = ''} onChange={(e) => (upload_hash(e))} accept=".csv, text/csv" />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <Form.Label htmlFor="">Original list of recipients</Form.Label>
                                                            <CSVLink data={csvDataSample} filename="sampleRecipients.csv" className='download-csv-link'>Download Sample CSV</CSVLink>
                                                        </div>
                                                        <div className='textarea_row'>
                                                            {/* <div className='listing_div text-center' >
                                                                <p >1</p>
                                                            </div> */}
                                                            <Form.Group style={{ flex: "1" }}>
                                                                {/* <Form.Control as="textarea" /> */}

                                                                {modeLoaded && <CodeMirror
                                                                    value={recipientList}
                                                                    options={{
                                                                        theme: "material",
                                                                        lineNumbers: true,
                                                                    }}
                                                                    ref={recpEditor}
                                                                    onBeforeChange={(editor, data, value) => {
                                                                        setRecipientList(value)
                                                                    }}
                                                                />}
                                                            </Form.Group>
                                                        </div>
                                                        <div className="upload_input_1 my-3">
                                                            <label className="upload_jsonbtn me-2" htmlFor="react-csv-reader-input2">Upload CSV</label>
                                                            <div className="react-csv-input">
                                                                <input className="csv-input" type="file" id="react-csv-reader-input2" name="react-csv-reader-input" onClick={(e) => e.target.value = ''} onChange={(e) => (upload_address(e))} accept=".csv, text/csv" />
                                                            </div>
                                                        </div>
                                                        {/* <div className='my-3'>
                                                        <input type="file" accept=".csv" onClick={(e) => e.target.value = ''} onChange={(e) => (upload_address(e))} />

                                                    </div> */}
                                                        {
                                                            mergeDiv ?
                                                                <div className='d-flex justify-content-evenly'>
                                                                    <button className='upload_csvbtn me-2' type='button' onClick={() => mergeDuplicateRecords()}>Merge duplicates</button>
                                                                    <button className='upload_csvbtn me-2' type='button' onClick={() => {
                                                                        showMergeDiv(false)
                                                                        validateTxn()
                                                                    }}>Proceed without merging</button>
                                                                </div>
                                                                :
                                                                <div className='mt-4 text-center'>
                                                                    <button type='button' className='upload_jsonbtn nextbtn ms-2 verify-btn' onClick={() => verifyAirDrop()}>
                                                                        Verify
                                                                    </button>
                                                                </div>
                                                        }

                                                        {
                                                            showInfo ?
                                                                <>
                                                                    <div className='mb-3'>
                                                                        <div className='row'>
                                                                            <div className='col-md-3'>
                                                                                <p>Duplicates ({result.duplicates.length - 1})</p>
                                                                                {
                                                                                    result.duplicates.length > 1 ?
                                                                                        <CSVLink data={result.duplicates} filename='duplicates' style={{ color: "#4ABAEA" }}>Download CSV</CSVLink>
                                                                                        : ""
                                                                                }
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <p>Matched ({result.matched.length - 1})</p>
                                                                                {
                                                                                    result.matched.length > 1 ?
                                                                                        <CSVLink data={result.matched} filename='matched' style={{ color: "#4ABAEA" }}>Download CSV</CSVLink>
                                                                                        : ""
                                                                                }
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <p>Received ({result.received.length - 1})</p>
                                                                                {
                                                                                    result.received.length > 1 ?
                                                                                        <CSVLink data={result.received} filename='received' style={{ color: "#4ABAEA" }}>Download CSV</CSVLink>
                                                                                        : ""
                                                                                }
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <p>Not Received ({result.notReceived.length - 1})</p>
                                                                                {
                                                                                    result.notReceived.length > 1 ?
                                                                                        <CSVLink data={result.notReceived} filename='notReceived' style={{ color: "#4ABAEA" }}>Download CSV</CSVLink>
                                                                                        : ""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='bg-info p-3 border-primary border'>
                                                                        <p><span><span className='text-600'>Duplicates</span> - addresses that were found duplicated in your actual txs from your original list.</span></p>
                                                                        <p><span><span className='text-600'>Matched</span> - recipients that were found in your actual txs from your original list.</span></p>
                                                                        <p><span><span className='text-600'>Received</span> - total number of recipients from your txs.</span></p>
                                                                        <p><span><span className='text-600'>Not received</span> - mismatch was detected from your original list. There is an address that either did not receive tokens, or it did not receive the full amount.</span></p>
                                                                    </div>
                                                                </>
                                                                : ""
                                                        }
                                                        {
                                                            errors.length ?
                                                                <div className='py-2'>

                                                                    <div className='text-white text-center py-2 error-message'>
                                                                        {
                                                                            errors.map((error, index) =>
                                                                                <>
                                                                                    <small key={index}>
                                                                                        {error}
                                                                                    </small>
                                                                                    <br />
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>

                                                                </div>
                                                                : ""
                                                        }
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <Footer /> */}
            </div >
        </>
    );
};

export default Stepthree;