import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MoralisProvider } from "react-moralis";
ReactDOM.render(
  <React.StrictMode>
    {/* <MoralisProvider appId="uKc57h2rbhG6PaFkl4KaccfOoOoc6jFDNY8IAuiA" serverUrl="https://dpjnw6v3joic.usemoralis.com:2053/server"> */}
    <MoralisProvider appId="LzVSAtspfphdJ8uUMLGxI7v0XeV7KOWOlXYfUtXT" serverUrl="https://uy6flaadytxv.usemoralis.com:2053/server">
      <App />
    </MoralisProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
