import React, { useEffect, useState, useContext, useRef } from 'react';
import { Form } from "react-bootstrap";
import Switch from 'react-switch';
import 'react-step-progress/dist/index.css';
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import Web3 from "web3";
import Modal from 'react-modal';
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/theme/material.css';
import 'codemirror/lib/codemirror.css';
import Papa from "papaparse";
import { CSVLink } from 'react-csv';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import { userServices } from '../../services/user.services';
import Header from '../Header';

const Stepone = ({ addressEditor, balance, metaNetwork, editorValue, setEditorValue, inputValue, setInputValue, selectValue, setSelectValue, symbol, showBal, userAddress, chainID, setLoader }) => {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    let subtitle;

    const [modalIsOpennewpass, setIsOpennewpass] = React.useState(false);
    const [toggle, set_toggle] = useState(false);
    const [tokenOptions, setTokenOptions] = useState()
    let modeLoaded = false

    const csvDataSample = [
        ["0x82d244a267b53e0a73e552e5883ab7a5eb16ac2c", "0.0001"],
        ["0x695bc3d9fb940bc3e0b29a18dcd6b633c920dab2", "0.0001"],
        ["0xff97e76d6d47d8580be26f5e5fffdca2d16bd5dd", "0.0001"],
        ["0xde59e859bc0bf6fdb3bf5caf034dde9950be1277", "0.0001"],
        ["0xa4c63dc6ab5502525e742767309c281e6be2a043", "0.0001"],
    ]

    const cryptoApiKey = 'b29321cc73d9373c34f97930f67753cd5279bacb'

    useEffect(() => {
        let blockchain = 'ethereum'
        if (metaNetwork) {
            setTokenOptions([{ value: metaNetwork, label: `${metaNetwork} Test Network` }])
        }
        if (chainID == 3 || chainID == 97) {
            if (chainID == 97) blockchain = 'binance-smart-chain'
            let params = {
                blockchain: blockchain,
                network: metaNetwork.toLocaleLowerCase(),
                address: userAddress
            }
            setLoader(true)
            userServices.getUserTokens(params).then(resp => {
                setLoader(false)
                const tokenResp = resp.data.data.data
                if (tokenResp && tokenResp.total > 0) {
                    let tokens = tokenResp.items.filter((item) => item.type == 'ERC-20')
                    if (tokens.length) {
                        tokens = tokens.map((item) => ({ value: item.contractAddress, label: `${item.name} - ${item.symbol}` }))
                        console.log(tokens)
                    }
                    setTokenOptions(options => options.concat(tokens))
                }
            }).catch(err => setLoader(false))
        }
    }, [metaNetwork])

    if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
        require('react-codemirror2')
        modeLoaded = true
    }

    function afterOpenModalnewpass() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    const upload_address = (e) => {
        toast.dismiss()
        let file = e.target.files[0]
        if (file.type !== 'text/csv') {
            toast.error('Must upload csv file.')
        } else {
            Papa.parse(file, {
                complete: function (results) {
                    let csvTemp = results.data.filter(val => val != '')
                    let editorData = results.data.filter(function (val) {
                        return val[0].trim() != 'address'
                    }).join("\n")
                    setEditorValue(editorData)
                }
            })
        }
    }

    const upload_address_json = (e) => {
        toast.dismiss()
        let file = e.target.files[0]
        if (file.type != 'application/json') {
            toast.error('Must upload JSON file.')
        } else {
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function (data) {
                let jsonData = JSON.parse(data.target.result.trim())
                let validFile = true
                let editorData = []
                jsonData.forEach((element, index) => {
                    if (element["address"] == undefined || element["amount"] == undefined) {
                        validFile = false
                    } else {
                        editorData.push([element["address"], element["amount"]])
                    }
                });
                if (validFile) {
                    setEditorValue(editorData.join("\n"))
                } else {
                    toast.error("Invalid JSON file.")
                }
            }
        }
    }

    const history = useHistory()

    var web3 = new Web3(window.ethereum);

    const airdrop = () => {
        history.push('/verification')
    }
    return (
        <>
            {/* First step */}
            {/* <Header /> */}
            <section className=''>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 m-auto'>
                            <div className='multisender_page pt-4'>
                               
                                <div className='row '>
                                    <div className='col-md-12 p-0'>
                                        <div className='content-box'>
                                            <div className='pt-0'>
                                                <h2>Welcome to one click & mass token <span> Crypto transfer solution</span></h2>
                                                <p className='my-3 mt-2'>A tool that allows you to batch send Native Currency, <span> ERC20, BEP20</span> tokens</p>
                                                <div className='list1'>
                                                    <span onClick={airdrop} style={{ cursor: "pointer" }}>
                                                        {/* <img src={require('../../Images/ethereum.svg').default} alt="" className='me-2' /> */}
                                                        Verification
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 p-0'>
                                        <div className='token-image-left'>
                                            <img src={require('../../Images/nft-image.png').default} alt="" />
                                        </div>
                                    </div>
                                    <div className='col-lg-7 col-md-12 col-sm-12 p-0'>
                                        {/* <Form> */}
                                        <div className='outer-box-area'>
                                            <div className='flex_frow'>
                                                <div style={{ width: "100%" }}>
                                                    <Form.Label htmlFor="">Token Address</Form.Label>
                                                    <Form.Group className="mb-3" style={{ flex: "1" }}>
                                                        {
                                                            showBal ?
                                                                <p>Balance: {balance} {symbol}</p>
                                                                : ""
                                                        }
                                                        <div className='position_relative'>
                                                            <CreatableSelect
                                                                styles={{
                                                                    control: (base, state) => ({
                                                                        // ...base,
                                                                        background: "hsl(37.75deg 74.17% 52.94% / 0%)",
                                                                        border: "1px solid #e09e2e5c",
                                                                        padding: "4px",
                                                                        borderRadius: "10px",
                                                                        color: "#fff",
                                                                    }),
                                                                    input: (base, state) => ({
                                                                        ...base,
                                                                        color: "white"
                                                                    })
                                                                }}


                                                                placeholder="Select your token or paste contract address."
                                                                options={tokenOptions}
                                                                inputValue={inputValue}
                                                                onInputChange={setInputValue}
                                                                value={selectValue}
                                                                onChange={setSelectValue}
                                                                onBlur={() => {
                                                                    if (inputValue) {
                                                                        setSelectValue(v => ({ label: inputValue, value: inputValue }))
                                                                    }
                                                                }}
                                                                components={{ DropdownIndicator: null }}
                                                            />

                                                            {/* <Form.Select>
                                                        <option value={0}>{metaNetwork} Test Network</option>
                                                    </Form.Select>
                                                    <img src={require('../../Images/arrow_down.svg').default} alt="" className='me-2 arrow_img' /> */}

                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className=''>
                                                <Form.Label htmlFor="" className='d-block'>Deflationary</Form.Label>
                                                <Switch onChange={() => set_toggle(!toggle)} checked={toggle} className={toggle ? 'switch' : 'switchafter'}
                                                    offColor="#0a4a7c"
                                                    onColor="#4abaea"
                                                    activeBoxShadow='0 0 2px 3px #3bf'
                                                />
                                            </div> */}
                                            </div>
                                            <div className='d-flex justify-content-between mt-2'>
                                                <Form.Label htmlFor="">List of Addresses, Amount</Form.Label>
                                                <div className='addresses-lists'>
                                                <CSVLink data={csvDataSample} filename="sample.csv" style={{ color: "#fff" }}>Download Sample CSV</CSVLink>
                                                    <a href='/sample.json' target="_blank" style={{ color: "#fff" }}>Download Sample JSON</a>
                                                    </div>
                                            </div>
                                            {modeLoaded && <CodeMirror
                                                value={editorValue}
                                                options={{
                                                    theme: "material",
                                                    lineNumbers: true,
                                                }}
                                                ref={addressEditor}
                                                onBeforeChange={(editor, data, value) => {
                                                    setEditorValue(value)
                                                }}
                                            />}
                                            <div className='mt-3 upload-csv-json'>
                                                <div className="upload_input_1">
                                                    <label className="upload_jsonbtn me-2" htmlFor="react-csv-reader-input2">Upload CSV</label>
                                                    <div className="react-csv-input">
                                                        <input className="csv-input" type="file" id="react-csv-reader-input2" name="react-csv-reader-input" onClick={(e) => e.target.value = ''} onChange={(e) => (upload_address(e))} accept=".csv, text/csv" />
                                                    </div>
                                                </div>

                                                <div className="upload_input_1">
                                                    <label className="upload_jsonbtn me-2" htmlFor="react-json-reader-input2">Upload JSON</label>
                                                    <div className="react-csv-input">
                                                        <input type="file" id='react-json-reader-input2' accept='.json' onClick={(e) => e.target.value = ''} onChange={upload_address_json} />
                                                    </div>
                                                </div>

                                                {/* <button className='upload_csvbtn'>
                                                How to Use
                                            </button> */}
                                            </div>
                                        </div>
                                        {/* </Form> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={modalIsOpennewpass}
                    onAfterOpen={afterOpenModalnewpass}
                    onRequestClose={() => setIsOpennewpass(false)}
                    style={customStyles}
                    contentLabel="Example Modal">
                    <div className="Password-new-box">
                        <div className='d-flex justify-content-between border-bottom pb-2'>
                            <h2 ref={(_subtitle) => (subtitle = _subtitle)} className="sample_title">Sample CSV</h2>
                            <div className='text-end'>
                                <button className="cross-btn bg-transparent border-0" onClick={() => setIsOpennewpass(false)}><img className="cross-image" src={require('../../Images/cross.svg').default} alt="" style={{ width: "15px" }} /></button>
                            </div>
                        </div>
                        <div className='stepper_table'>
                            <table className=''>
                                <thead>
                                    <tr>

                                        <th>Address</th>
                                        <th style={{ width: "150px" }} className="text-end">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>dfsvdfv fdvf dvdfgv fdvf df</td>
                                        <td className='amount_p' align='end'>1212</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </Modal>
            </section>
        </>
    );
};

export default Stepone;