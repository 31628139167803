import { Container } from "react-bootstrap";
import Header from "./Header";

const AboutUs = () => {
    return (
        <>
            <Header />
            <section className="aboutus-area">
                <Container>
                    <div className="about-us-content-inner">
                        <h3>About Token Airdrop</h3>
                        <p>Welcome to TokenAirdrop, a revolutionary platform designed to simplify and enhance the process of distributing rewards, NFTs (Non-Fungible Tokens), and cryptocurrencies to multiple recipients effortlessly. Whether you're a business looking to engage your community or an individual rewarding supporters, TokenAirdrop offers a seamless solution tailored to your needs.</p>
                        <h5>Our Mission</h5>
                        <p>At TokenAirdrop, our mission is to democratize reward distribution in the digital economy. We believe that everyone should have access to efficient tools for engaging and incentivizing their audience. By harnessing blockchain technology, we aim to empower businesses, creators, and communities to leverage tokenized rewards effectively.</p>
                        <h5>How It Works</h5>
                        <p>TokenAirdrop streamlines reward distribution into a simple process:</p>
                        <ul>
                            <li><b>Upload Recipient List:</b> Prepare your recipient list in an Excel spreadsheet, including wallet addresses and reward details.</li>
                            <li><b>Select Rewards:</b> Choose the type and quantity of rewards you wish to distribute (tokens or NFTs).</li>
                            <li><b>Customize Settings:</b> Set distribution parameters such as distribution timing and any special conditions.</li>
                            <li><b>Initiate Airdrop:</b>With a click of a button, TokenAirdrop securely distributes rewards to all listed recipients.</li>
                        </ul>
                        <p>Our platform automates the distribution process, ensuring accuracy and efficiency while saving you valuable time.</p>
                        <h5 className="mb-3">Key Features</h5>
                        <div className="features-content">
                            <b>Bulk Reward Distribution</b>
                            <p>Effortlessly send rewards to large groups of recipients simultaneously, eliminating the need for manual, individual transfers.</p>
                        </div>
                        <div className="features-content">
                            <b>Excel Upload</b>
                            <p>Easily import recipient data from Excel spreadsheets, allowing for seamless integration with your existing databases.</p>
                        </div>
                        <div className="features-content">
                            <b>Customizable Options</b>
                            <p>Tailor your reward distributions by specifying token types, quantities, and any additional parameters to suit your unique requirements.</p>
                        </div>
                        <div className="features-content">
                            <b>Secure and Transparent</b>
                            <p>Built on blockchain technology, TokenAirdrop ensures the security and transparency of all transactions. Each distribution is recorded on the blockchain, providing an immutable record of reward allocations.</p>
                        </div>
                        <h5>Our Team</h5>
                        <p>TokenAirdrop was founded by a dedicated team of blockchain enthusiasts and experienced software developers. With a shared passion for innovation and a deep understanding of blockchain technology, our team is committed to delivering a user-friendly platform that simplifies reward distribution for businesses and individuals alike.</p>

                        <h5>Get Started</h5>
                        <p>Ready to revolutionize your reward distribution strategy? Sign up for TokenAirdrop today and discover a new way to engage and incentivize your audience through tokenized rewards.</p>
                        <p>Join us on our mission to unlock the full potential of tokenized incentives in the digital age.
                        </p>
                    </div >
                </Container >
            </section >
        </>
    )
}
export default AboutUs;