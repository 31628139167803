import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './Components/Scrolltop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageStepper from './Components/PageStepper';
import Airdrop from './Components/Airdrop';
import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from './Components/NotFound';
import AboutUs from './Components/AboutUs';
import Faq from './Components/Faq';

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path='/verification' component={Airdrop} />
            <Route path='/about-us' component={AboutUs} />
            <Route path='/faq' component={Faq} />
            <Route exact path='/' component={PageStepper} />
            <Route path='*' component={NotFound} />
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
