let auth = localStorage.getItem('auth');



export function formDataHeaders() {
    return Object.assign({}, { "Content-Type": "application/x-www-form-urlencoded", 'Authorization': auth ? auth : '4e72877abdbbff1922f2' }, commonHeaders);
    // console.log(Object);


}

export function jsonHeaders() {
    return Object.assign({}, { "Content-Type": "application/json", 'Authorization': auth ? auth : '4e72877abdbbff1922f2' }, commonHeaders);
}

export function jsonHeadersBots() {
    return Object.assign({}, {
        "X-API-KEY": "1H53KIW973ALEke32n4N42",
        "Content-Type": "application/json",
        'deviceType': 'w',
        'appVersion': '1.0',
        'Access-Control-Allow-Origin': 'true',
        'timezone': 'Asia/Kolkata',
    },
    );
}


function commonHeaders() {
    return {
        'deviceType': 'w',
        'appVersion': '1.0',
        'Access-Control-Allow-Origin': 'true',
        'timezone': 'Asia/Kolkata',
        'authorization': auth,
    }
}