import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, Dropdown, Modal as BsModal, CloseButton, NavDropdown } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from "react-toastify";
import { ToastMessage } from "../config/ToastMessages";
import { useMoralis } from "react-moralis";
import Web3 from "web3";
import { useHistory, useLocation } from "react-router";


const Header = ({ updateUserAddress }) => {
    const [user_address, setUserAddress] = useState("")
    const { Moralis, userError, logout } = useMoralis();
    let history = useHistory();


    useEffect(() => {
        if (!window.ethereum) {
            toast.error("No crypto wallet found. Please install it.")
        }
        else {
            let CheckConnectWalllet = JSON.parse(localStorage.getItem("disconnect"))
            if (CheckConnectWalllet == false) {
                let userAddress = localStorage.getItem('address')
                if (userAddress) {
                    setUserAddress(userAddress)
                }

            }
            window.ethereum.enable();
            let web3 = new Web3(window.ethereum)

            console.log('provider ', window.ethereum.selectedAddress, window.ethereum.networkVersion)
        }
    }, [user_address, window?.ethereum?.selectedAddress])


    async function disconnectWallet() {

        localStorage.removeItem("cuurent_provider")
        logout()
        // const disconnect = web3.eth.currentProvider.disconnect()
        localStorage.setItem('disconnect', 'true');
        localStorage.removeItem('address');
        toast.success(ToastMessage('WALLET_DISCONNECT_SUCCESS'))
        localStorage.setItem('auth', '4e72877abdbbff1922f2')
        // history.push('/');
        window.location.reload();
    }

    const connectWallet = async () => {
        await Moralis.authenticate().then(resp => {
            if (!userError) {
                let currentuser = Moralis.User.current();
                localStorage.setItem('disconnect', 'false')
                let walletAddress = currentuser.get('ethAddress')
                localStorage.setItem('address', walletAddress)
                setUserAddress(walletAddress)
                updateUserAddress(walletAddress)
            }
        })

    }
    return (
        <>
            <section className="header">
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Brand href="/">Token Airdrop</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link onClick={() => history.push('/about-us')}>About Us</Nav.Link>
                                <Nav.Link onClick={() => history.push('/faq')} >FAQs</Nav.Link>
                                <Nav.Link >
                                    <div style={{ position: "relative" }}>
                                        {user_address ?
                                            <>
                                                <div className='' style={{ textAlign: "right" }} >
                                                    <span className="text-white" style={{ marginRight: "32px" }} >Your address: <b>{user_address.slice(0, 5) + "...." + user_address.slice(user_address.length - 4, user_address.length)}</b></span>
                                                    <button onClick={() => disconnectWallet()} className='upload_jsonbtn' type='button'>
                                                        Disconnect
                                                    </button>
                                                </div>
                                            </>
                                            :
                                            <button onClick={() => connectWallet()} className='upload_jsonbtn' type='button'>
                                                Connect Wallet
                                            </button>
                                        }</div>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </section>
        </>
    )
}

export default Header
