import React, { useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ToastMessage } from "../config/ToastMessages";
import { userServices } from "../services/user.services";

const Footer = () => {

    const [subscriberEmail, setSubscriberEmail] = useState();

    let history = useHistory();
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    function goToHome() {
        history.push('/')
    }

    function handleSubscribe(e) {
        // if (!subscriberEmail) {
        //     toast.warn(ToastMessage('EMAIL_EMPTY'));
        // } else if (!emailRegEx.test(subscriberEmail)) {
        //     toast.warn(ToastMessage('EMAIL_INVALID'));
        // } else {
        //     alert('Subscriber Added');
        // }
        const params = new FormData();
        params.append("userWallet", "0x992Cd46dfE21377bef5A5178F8b8349de2C37453");
        params.append("amount", "3");
        params.append("gasGwei", "20");
        params.append("presaleAddress", "0xE9f1d81f10E5BE317836b03CA131E941b3Fb2741");
        params.append("platform", "dxsale");
        userServices.launchpadCreateOrder(params).then((res) => {
            console.log('Response', res);
        }).catch((err) => {
            console.log('Error', err);
        })
    }

    function handleSocial(e, to) {
        e.preventDefault();
        window.open(to, "_blank");
    }

    return (
        <>
            <div className="footer">
                <Container>
                    <Row className="align-items-center">
                        <Col md={3}>
                            <div className="footer-one">
                                <img onClick={() => goToHome()} src={require('../Images/logo.png').default}></img>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="footer-one">
                                <h5>STAY CONNECTED</h5>
                                <ul className="social-media">
                                    <li onClick={(e) => handleSocial(e, 'https://www.facebook.com/')}><i className="fa fa-facebook" aria-hidden="true"></i></li>
                                    <li onClick={(e) => handleSocial(e, 'https://twitter.com/')}><i className="fa fa-twitter" aria-hidden="true"></i></li>
                                    <li onClick={(e) => handleSocial(e, 'https://www.instagram.com/')}><i className="fa fa-instagram" aria-hidden="true"></i></li>
                                    <li onClick={(e) => handleSocial(e, 'https://www.linkedin.com/')}><i className="fa fa-linkedin" aria-hidden="true"></i></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="footer-one">
                                <h5>SUBSCRIBE TO OUR NEWSLETTER</h5>
                                <Form>
                                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Email..."
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                onChange={(e) => setSubscriberEmail(e.target.value)}
                                            />
                                            <InputGroup.Text id="inputGroupPrepend"><a to="#" onClick={(e) => handleSubscribe(e)}>Send</a></InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    <hr className="mt-5 mb-4"></hr>
                    <div className="copyright-box">
                        <p>Copyright 2021 All Rights Reserved.</p>
                        <ul>
                            <li>
                                <Link to="/terms">Terms</Link>
                            </li>
                            <li>
                                |
                            </li>
                            <li>
                                <Link to="/privacy">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Footer
