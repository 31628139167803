import { contains } from 'jquery';
import React, { useState, useContext, useEffect } from 'react';
import { Button, Form, OverlayTrigger, Pagination, Tooltip } from "react-bootstrap";
import ReactPaginate from 'react-paginate'
import 'react-step-progress/dist/index.css';
import Web3 from "web3";
import { useMoralis } from "react-moralis";

const Steptwo = ({ addressAmountList, balance, AmountTotal, symbol, tokenABI, contractAddress, provider, userAddress, network, tokenValue, decimals, setIsApproved }) => {

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerpage = 10;
    const pageVisited = pageNumber * usersPerpage;
    const [allowed, setAllowed] = useState("")
    const { Moralis } = useMoralis()
    const ChangePage = ({ selected }) => {
        setPageNumber(selected)
    }


    useEffect(() => {
        if (network != tokenValue) {
            getAllowance()
        }
    }, [])

    async function getAllowance() {
        const web3 = new Web3(window.ethereum)
        let tokenAbi = new web3.eth.Contract(tokenABI, tokenValue,)
        let allow = await tokenAbi.methods.allowance(userAddress, contractAddress).call()
        const amtAllow = Number(allow) / (10 ** decimals)
        setAllowed(allow != '0' ? (amtAllow) : "0")
        if (amtAllow >= AmountTotal) {
            setIsApproved(true)
        }
    }

    const items = addressAmountList
    const DisplayUsers = items.slice(pageVisited, pageVisited + usersPerpage)
    const PageCount = Math.ceil(items.length / usersPerpage)

    return (
        <>
            {/* Second step */}
            <section className='pt-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-10 col-lg-10 col-md-12 m-auto'>
                            <div className='multisender_page'>
                           
                                <div className='row align-items-center'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 m-auto p-0'>
                                        <div className='steptwo-area'>
                                            <div className='py-4 text-center pt-0'>
                                                <h2 className='mb-3'><span>Total Tokens for this Airdrop</span></h2>
                                            </div>
                                            <div className='table_head_data d-flex justify-content-between pb-2 mb-3'>
                                                <h5>Address ({addressAmountList.length})</h5>
                                                {/* <h5>Address (0)</h5> */}
                                                <h6>Total Amount</h6>
                                            </div>
                                            <div className='detials_table'>
                                                <table>
                                                    <tbody>
                                                        {
                                                            DisplayUsers.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.address}</td>
                                                                    <td className='amount_p' align='end'>{item.amount}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>


                                                </table>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-end mt-4 pagination_row'>
                                                <ReactPaginate
                                                    previousLabel={"prev"}
                                                    nextLabel={'next'}
                                                    pageCount={PageCount}
                                                    onPageChange={ChangePage}
                                                    containerClassName={"paginationBttns"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"paginationDisabled"}
                                                    activeClassName={'paginationActive'}

                                                />
                                            </div>
                                            {/* <div className='my-4 text-center'>
                                            <button className='upload_jsonbtn nextbtn ms-2'>
                                                Next
                                                <img src={require('../../Images/next_white.svg').default} alt="" className='ms-3' />
                                            </button>
                                        </div> */}
                                            <div className='mb-3 mt-3'>
                                                <p className='text-center'><i>Total estimate will be displayed on the next page</i></p>
                                            </div>
                                            <div className='below_data'>
                                                <div className='data_row mb-2'>
                                                    <p className='mb-0'>How many tokens you approved to send</p>
                                                    <p className='data_amount mb-0'> {allowed ? allowed : balance} {symbol}</p>
                                                </div>
                                                <div className='data_row mb-2'>
                                                    <p className='mb-0'>Total Tokens for this Airdrop</p>
                                                    <p className='data_amount mb-0'>{network != tokenValue ? Number(AmountTotal).toFixed(decimals).replace(/\.?0+$/, "") : Number(Number(AmountTotal).toPrecision(8))} {symbol}</p>
                                                </div>
                                                <div className='data_row mb-2'>
                                                    <p className='mb-0'>Your token balance</p>
                                                    <p className='data_amount mb-0'>{balance} {symbol}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Second step */}
        </>
    );
};

export default Steptwo;