import React, { useState, useEffect, useContext, useRef } from 'react';
import { Alert, Button, Spinner, Container, Nav, Navbar, } from "react-bootstrap";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import Web3 from "web3";
import Stepone from './stepper/Stepone';
import Steptwo from './stepper/Steptwo';
import Stepthree from './stepper/Stepthree';
import { userServices } from '../services/user.services';
import { useMoralis } from "react-moralis";
import { config } from '../config/config';
import { ToastMessage } from '../config/ToastMessages';

const PageStepper = () => {
    const history = useHistory();
    // var rinky_provider = "https://rinkeby.infura.io/v3/162f2b69e8eb48a2bcd15dca05a3b996";
    // var Ropsten_provider = "https://ropsten.infura.io/v3/162f2b69e8eb48a2bcd15dca05a3b996";
    var rinky_provider = "https://sepolia.infura.io/v3/94802db6ed4a4985902ebf2f47173b0e";
    var Ropsten_provider = "https://sepolia.infura.io/v3/94802db6ed4a4985902ebf2f47173b0e";
    var bsc_testnet_provider = "https://data-seed-prebsc-1-s1.binance.org:8545";
    // var rinkebyURL = 'https://rinkeby.etherscan.io/tx/'
    // var ropstenURL = 'https://ropsten.etherscan.io/tx/'
    var rinkebyURL = 'https://sepolia.etherscan.io/tx/'
    var ropstenURL = 'https://sepolia.etherscan.io/tx/'
    var bscURL = 'https://testnet.bscscan.com/tx/'
    var rink_id = 4;
    var bsc_id = 97;
    const MATIC_MAINNET = 137;
    const MATIC_MAINNET_RPC = "https://polygon-mainnet.g.alchemy.com/v2/0x4V4BZOZaDOcVRat5L6CQTcA2QTYWqD";
    const MATIC_MAINNET_SCAN_URL = "https://polygonscan.com/tx/";
    const MATIC_MAINNET_CONTRACT = "0x98D6B70953DA81Fc8f9281148905a3900e607553";


    // var ropsten_id = 3;
    var ropsten_id = 11155111;
    var tmatic_id = 80001
    let abi = require('./MultisenderAbi.json');
    let tokenABI = require('./erc20abi.json');

    const [steps, setSteps] = useState({ step1: true, step2: false, step3: false })
    const { web3, enableWeb3, user, Moralis, isWeb3Enabled, isAuthenticated, userError, logout } = useMoralis();
    // const bsc_testnet = "0x6e336ed1549309dC43C18689815D4861757e65A5";
    // const rinkeby_test_net = "0x37f7026AC5eEc5293dbc2d702866854125e5d9af";
    // const Ropsten = "0x31C073290A18E71CeaE760Bbcaca9EC6a0853135";
    const bsc_testnet = "0x93b61C74f4EEF6F6bD5877C0FaE69e80314a4DE6";
    const rinkeby_test_net = "0xEbc527d74CE3f702F85D2E50BF7c3EAdB820d561";
    const Ropsten = "0xEbc527d74CE3f702F85D2E50BF7c3EAdB820d561";
    const [chainID, setChainID] = useState("")
    const [contractAddress, setContractAddress] = useState("");
    const [httpprovider, setHttpprovider] = useState("");
    const [user_address, setUserAddress] = useState("")
    const [hashList, setHashList] = useState([]);
    const [proceedDuplicates, setProceedDuplicates] = useState(false)
    const [mergeDiv, showMergeDiv] = useState(false)
    const [duplicateAddresses, setDuplicateAddresses] = useState([])
    const [editorErrors, setEditorErrors] = useState([])
    const [Network, setNetwork] = useState('')
    const [Account_balance, setaccount_Balance] = useState(0)
    const [Meta_Network, setMeta_Network] = useState('')
    const addressEditor = useRef()
    const [editorValue, setEditorValue] = useState("")
    const [addressAmountList, setAddressAmountList] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [testURL, setTestURL] = useState("")
    const [uniqueIDs, setUniqueIDs] = useState([])
    const [amounts, setAmounts] = useState(0)
    const [hideNext, setHideNext] = useState(false)
    const [closeBtn, setCloseBtn] = useState(false)
    const [loader, setLoader] = useState(false);
    const batchCount = useRef(0)
    const [temp, setTemp] = useState(0)
    const [batchTxns, setBatchTxns] = useState([])
    const [gweiFee, setGweiFee] = useState(2.5);
    const [gweiMaxPrice, setGweiMaxPrice] = useState(0);
    const [tempAccount, setTempAccount] = useState({})
    const [txnMethod, setTxnMethod] = useState("")
    const [transferHash, setTransferHash] = useState("")
    const [selfProcessing, setSelfProcessing] = useState(false)
    const [amountWithFee, setAmountWithFee] = useState(0)
    const [showCloseButton, setShowCloseButton] = useState(false)
    const [isTransferred, setIsTransferred] = useState(false)
    const [selectValue, setSelectValue] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [decimals, setDecimals] = useState(18)
    const [isApproved, setIsApproved] = useState(false)
    const [transferLimit, setTransferLimit] = useState(0)
    const [showBal, setShowBal] = useState(false)
    const [approveTxn, setApproveTxn] = useState({ hash: "", status: -1 })
    const [symbol, setSymbol] = useState("rETH")
    const tokenSel = "0x0f192482583857E0CB9b1aDE96EA0BEe9c017DA6";
    const user_address_ref = useRef(null);

    useEffect(() => {

        if (!window.ethereum) {
            toast.error("No crypto wallet found. Please install it.")
        }
        else {
            let CheckConnectWalllet = JSON.parse(localStorage.getItem("disconnect"))
            if (CheckConnectWalllet == false) {
                let userAddress = localStorage.getItem('address')
                if (userAddress) {
                    setUserAddress(userAddress)
                }
                setTimeout(() => {
                    HandleNetwork()
                }, 1000)
            } else {
                setLoader(false)
            }
            window.ethereum.enable();
            let web3 = new Web3(window.ethereum)

            console.log('provider ', window.ethereum.selectedAddress, window.ethereum.networkVersion)
            let walletAddress = window.ethereum.selectedAddress;
            if (walletAddress) {
                user_address_ref.current = walletAddress
            }
            // else {
            //     if (Moralis && Moralis.User && Moralis.User.current()) {
            //         let currentuser = Moralis.User.current();
            //         let walletAddress = currentuser?.get('ethAddress')
            //         user_address_ref.current=walletAddress;
            //     }
            // }
            setNetwork(window.ethereum.networkVersion)
        }
        check_chain_id()
    }, [user_address, window?.ethereum?.selectedAddress])

    useEffect(() => {
        if (selectValue.value) {
            setLoader(true)
            check_chain_id()
        }
        if (selectValue?.value == Meta_Network) {
            getBalance()
        } else if (selectValue.value) {
            getTokenBalance()
        }
    }, [selectValue])

    async function connectWallet() {
        await Moralis.authenticate().then(resp => {
            if (!userError) {
                let currentuser = Moralis.User.current();
                localStorage.setItem('disconnect', 'false')
                let walletAddress = currentuser.get('ethAddress')
                localStorage.setItem('address', walletAddress)
                setUserAddress(walletAddress)
            }
        })
    }

    async function getTokenBalance() {
        // const web3 = new Web3(new Web3.providers.HttpProvider(httpprovider));
        const web3 = new Web3(window.ethereum);
        const wallet = user_address_ref.current
        console.log("selectValue?.value", selectValue?.value, wallet);
        let contract = ""
        let res = ''
        try {
            contract = new web3.eth.Contract(tokenABI, selectValue?.value);
            res = await contract.methods.balanceOf(wallet).call()
            // const symbolName = await contract.methods.symbol().call()
            // setSymbol(symbolName)
            setShowBal(true)
            setLoader(false)
        } catch (err) {
            console.log("err", err);
            toast.warning("Invalid token address.")
            setShowBal(false)
            setLoader(false)
            return false
        }
        const decimalPlaces = await contract.methods.decimals().call()
        setDecimals(Number(decimalPlaces))
        setaccount_Balance(res)
    }

    function checkWallet() {
        let WalletConnection = localStorage.getItem('disconnect')
        if (WalletConnection == 'true' || !WalletConnection) {
            toast.error("Wallet not connected")
            return true
        } else {
            return false
        }
    }

    async function getBalance() {
        if (window.ethereum) {
            let networkAdress = await window.ethereum.selectedAddress;
            var web3 = new Web3(window.ethereum)
            await web3.eth.getBalance(`${networkAdress}`, function (error, result) {
                try {
                    const Balance_Inether = web3.utils.fromWei(`${result}`, 'ether')
                    localStorage.setItem('Account_Balance', JSON.stringify(Balance_Inether))
                    setShowBal(true)
                    setaccount_Balance(Number(Balance_Inether).toPrecision(6))
                    setLoader(false)
                }
                catch {
                    setLoader(false)
                    console.log(error)
                }
            })
        }
    }

    async function HandleNetwork() {
        if (window.ethereum) {
            let Connected_Network = await window.ethereum.networkVersion;
            let network = window.ethereum;
            let networkAdress = await window.ethereum.selectedAddress;
            var web3 = new Web3(window.ethereum);

            // await web3.eth.getBalance(`${networkAdress}`, function (error, result) {
            //     try {
            //         const Balance_Inether = web3.utils.fromWei(`${result}`, 'ether')
            //         localStorage.setItem('Account_Balance', JSON.stringify(Balance_Inether))
            //         setShowBal(true)
            //         setaccount_Balance(Number(Balance_Inether).toPrecision(6))
            //         setLoader(false)
            //     }
            //     catch {
            //         setLoader(false)
            //         console.log(error)
            //     }
            // })
            const ChainID = web3.eth.net.getNetworkType(function (error, result) {
                setMeta_Network(result.toUpperCase())
            })
        } else {
            toast.error("No crypto wallet found. Please install it. Not Connected")

        }
    }

    const check_chain_id = async () => {
        if (localStorage.getItem('disconnect') == 'false') {
            const web3 = new Web3(window.ethereum);
            const chain = web3.eth.currentProvider.chainId;

            // console.log("ggggggg", web3.eth.currentProvider.chainId);
            // const chainIdCurrent = Moralis.getChainId();
            // Promise.resolve(chainIdCurrent).then(function (chain) {
            console.log("chainIdCurrent", chain);
            setChainID(window.ethereum.networkVersion)
            if (chain == bsc_id) {
                setContractAddress(bsc_testnet);
                setHttpprovider(bsc_testnet_provider);
                setTestURL(bscURL)
                setSymbol("BNB")
            } else if (chain == ropsten_id) {
                setContractAddress(Ropsten);
                setHttpprovider(Ropsten_provider);
                setTestURL(ropstenURL)
                setSymbol("rETH")
            } else if (chain == tmatic_id) {

            } else if (chain == MATIC_MAINNET) {
                setContractAddress(MATIC_MAINNET_CONTRACT);
                setHttpprovider(MATIC_MAINNET_RPC);
                setTestURL(MATIC_MAINNET_SCAN_URL);
                setSymbol("MATIC")
            } else {
                setContractAddress(rinkeby_test_net);
                setHttpprovider(rinky_provider);
                setTestURL(rinkebyURL)
                setSymbol("RETH")
            }
            // })
        } else {
            // console.log('hash not found')
        }
    }

    function mergeDuplicateRecords() {
        let address = addressEditor.current.editor.getValue().split("\n")
        let vals = address.filter((val, index) => duplicateAddresses.indexOf(index) == -1).filter(val => val != '')
        addressEditor.current.editor.setValue(vals.join("\n"))
        showMergeDiv(false)
        setEditorErrors({})
        setEditorValue(vals.join("\n"))
        setListingAddressAmount(vals.join("\n"))
        // setSteps({ step1: false, step2: true, step3: false })
    }

    async function validateEditor() {
        const web3 = new Web3(window.ethereum);
        let address = addressEditor.current.editor.getValue().split("\n")
        let success = true
        let err = []
        let tempAddresses = []
        let duplicates = []
        if (address.length) {
            if (address.length == 1 && address[0].trim() == '') {
                err.push("Please provide a corresponding amount for each address.")
                success = false
            } else {
                addressEditor.current.editor.doc.getAllMarks().forEach(marker => marker.clear());
                setLoader(true)
                // await sleep(2000)
                for (let indexAddress = 0; indexAddress < address.length; indexAddress++) {
                    const line = address[indexAddress];
                    if (line.trim() != '') {
                        let data = line.split(',')
                        let dataLength = data.toString().replaceAll(",", "").length
                        if (data.length != 2) {
                            success = false
                            if (data.length > 2) {
                                err.push(`Line ${indexAddress + 1}: Too many columns within the line.`)
                                addressEditor.current.editor.doc.markText({ line: indexAddress, ch: 0 }, { line: indexAddress, dataLength }, { className: "text-danger" })
                            } else if (data.length == 1) {
                                err.push("Invalid Record Length: expect 2, got 1 on Line " + (indexAddress + 1))
                                addressEditor.current.editor.doc.markText({ line: indexAddress, ch: 0 }, { line: indexAddress, dataLength }, { className: "text-danger" })
                            }

                        } else if (data[1].trim() == '' || isNaN(data[1])) {
                            success = false
                            err.push(`Line ${indexAddress + 1}: Wrong amount.`)
                            addressEditor.current.editor.doc.markText({ line: indexAddress, ch: 0 }, { line: indexAddress, dataLength }, { className: "text-danger" })
                        } else if (!web3.utils.isAddress(data[0])) {
                            success = false
                            err.push(`Line ${indexAddress + 1}: Invalid Address.`)
                            addressEditor.current.editor.doc.markText({ line: indexAddress, ch: 0 }, { line: indexAddress, dataLength }, { className: "text-danger" })
                        } else if (Meta_Network != selectValue?.value) {
                            let amtDecimal = data[1].split('.')
                            if (amtDecimal[1] && amtDecimal[1].length > decimals) {
                                success = false
                                err.push(`Line ${indexAddress + 1}: Invalid decimal format.`)
                                addressEditor.current.editor.doc.markText({ line: indexAddress, ch: 0 }, { line: indexAddress, ch: dataLength + 1 }, { className: "text-danger" })
                            }
                        } else {
                            if (!tempAddresses.includes(data[0])) {
                                tempAddresses.push(data[0])
                            } else {
                                duplicates.push(indexAddress)
                            }
                        }
                        try {
                            const convertedAmt = Number(web3.utils.toWei(Number(data[1]).toFixed(18).replace(/\.?0+$/, "")))
                        }
                        catch {
                            success = false
                            err.push(`Line ${indexAddress + 1}: Invalid decimal format.`)
                            addressEditor.current.editor.doc.markText({ line: indexAddress, ch: 0 }, { line: indexAddress, ch: dataLength + 1 }, { className: "text-danger" })
                        }
                    }
                }
                // address.forEach((line, indexAddress) => {
                // });
                if (duplicates.length && success) {
                    success = false
                    showMergeDiv(true)
                    setDuplicateAddresses(duplicates)
                    duplicates.forEach((element, indexDuplicate) => {
                        err.push(`Line ${element + 1}: Duplicate address.`)
                        let lineLength = (address[element].replaceAll(",", "").length) + 1
                        addressEditor.current.editor.doc.markText({ line: element, ch: 0 }, { line: element, ch: lineLength }, { className: "text-danger" })
                    });
                }
            }
        }
        setEditorErrors(err)
        return success
    }

    async function step1Validator() {
        toast.dismiss()
        let CheckWallet = localStorage.getItem('disconnect')
        if (CheckWallet == true) {
            console.log("hceck11")
            toast.warn('Wallet not connected')
            return false;
        }
        let proceed = await validateEditor()
        return proceed
    }

    async function step2Validator() {
        toast.dismiss()
        console.log("batchCount.current", batchCount.current);
        if (Account_balance < totalAmount) {
            toast.error("Not Enough Balance")
            return false;
        } else if (!isApproved && Meta_Network != selectValue?.value) {
            toast.warning("Please approve transaction to continue.")
            return false
        } else {
            let batch_txns = []
            for (let index = batchCount.current; index < uniqueIDs.length; index++) {
                if (index >= batchCount.current) {
                    if (selectValue.value != Meta_Network) {
                        let eGas = await tokenTxnGas(batchCount.current, 2000)
                        console.log('ssssssssss ', eGas)
                        batch_txns.push(eGas)
                    } else {
                        let eGas = await estimateGasForTxn(batchCount.current, 2000)
                        console.log('ssssssssss ', eGas)
                        batch_txns.push(eGas)
                    }
                }
            }
            setBatchTxns(batch_txns)
            return true
        }
    }

    async function estimateGasForTxn(batchStart, reduceBatch) {
        setLoader(true)
        let tempIds = [...uniqueIDs]
        let tempAmounts = [...amounts]
        let splicedAmount = tempAmounts.splice(batchStart, reduceBatch)
        let splicedAddresses = tempIds.splice(batchStart, reduceBatch)
        let AmountTotal = splicedAmount.reduce(function (x, y) { return x + y }, 0)
        if (AmountTotal) {
            const web3 = new Web3(window.ethereum)
            let tokenContractTmp = new web3.eth.Contract(abi, contractAddress);
            const gas_price = await web3.eth.getGasPrice()
            console.log('gassss  ', gas_price)
            return await tokenContractTmp.methods.transferChunks(splicedAddresses, splicedAmount.map(amt => String(amt)))
                .estimateGas({ from: user_address, value: AmountTotal }).then(gas => {
                    batchCount.current += reduceBatch
                    setLoader(false)
                    return { "addresses": splicedAddresses, "amounts": splicedAmount, "gas": gas, "amount": AmountTotal, "gasPrice": gas_price }
                }).catch(err => {
                    console.log(err)
                    setLoader(false)
                    return estimateGasForTxn(batchStart, (reduceBatch - 500))
                })

        } else {
            return null
        }
    }

    async function tokenTxnGas(batchStart, reduceBatch) {
        let tempIds = [...uniqueIDs]
        let tempAmounts = [...amounts]
        let splicedAmount = tempAmounts.splice(batchStart, reduceBatch)
        let splicedAddresses = tempIds.splice(batchStart, reduceBatch)
        let AmountTotal = splicedAmount.reduce(function (x, y) { return x + y }, 0)
        if (AmountTotal) {
            const web3 = new Web3(window.ethereum)
            web3.setProvider(httpprovider);
            // const web3 = await Moralis.enableWeb3({ provider: httpprovider });

            const tokenContractTmp = new web3.eth.Contract(abi, contractAddress)
            let gasFee = 0
            try {
                // console.log("user_address_ref.current, selectValue.value, splicedAddresses, splicedAmount.map(amt => String(amt))", user_address_ref.current, selectValue.value, splicedAddresses, splicedAmount.map(amt => String(amt)));
                gasFee = await tokenContractTmp.methods.transferChunksTokens(user_address_ref.current, selectValue.value, splicedAddresses, splicedAmount.map(amt => String(amt))).estimateGas({ from: user_address })
                console.log("gasFee------>", gasFee);
                batchCount.current += reduceBatch
            } catch (e) {
                console.log("gasFee--err---->", gasFee, e, contractAddress, user_address);
                return tokenTxnGas(batchStart, reduceBatch - 500)
            }
            console.log(gasFee)
            const gas_price = await web3.eth.getGasPrice()
            return { "addresses": splicedAddresses, "amounts": splicedAmount, "gas": gasFee, "amount": AmountTotal, "gasPrice": gas_price }
        }
    }

    async function setListingAddressAmount(value) {
        const web3 = new Web3(window.ethereum)
        addressEditor.current.editor.doc.getAllMarks().forEach(marker => marker.clear());
        let tempAddresses = []
        let address = value.split("\n").filter(val => val != '');
        let totalAmt = 0
        let unique_ids = []
        let amts = []
        let total_amount_wei = 0
        for await (let line of address) {
            let data = line.split(',')
            let amt = Number(data[1]).toFixed(decimals).replace(/\.?0+$/, "")
            if (!isNaN(data[1])) {
                totalAmt += Number(amt)
                tempAddresses.push({ "address": data[0], "amount": amt })
                let wei_amount = web3.utils.toWei(amt)
                if (selectValue.value != Meta_Network) {
                    if (decimals > 0) {
                        wei_amount = (Number(Number(data[1]).toFixed(decimals)) * (10 ** decimals))
                        console.log("wei_amount   ", wei_amount)
                    } else {
                        wei_amount = Number(data[1])
                    }
                }
                console.log()
                total_amount_wei += Number(wei_amount)
                amts.push(Number(wei_amount))
                unique_ids.push(data[0])
            }
        }
        let amtConverted = totalAmt
        if (selectValue.value != Meta_Network && decimals == 18) {
            amtConverted = Moralis.Units.FromWei(total_amount_wei)
        }
        setTotalAmount(amtConverted)
        setAddressAmountList(tempAddresses)
        setUniqueIDs(unique_ids)
        setAmounts(amts)
    }

    async function handleSteps(e) {
        if (checkWallet()) {
            return false
        }
        let btnID = e.target.id
        if (btnID == 'prev') {
            setHideNext(false)
            if (steps.step3) {
                setSteps({ step2: true, step3: false, step1: false })
            } else if (steps.step2) {
                setSteps({ step2: false, step3: false, step1: true })
            }
        } else {
            setLoader(true)
            if (steps.step1 && !user_address_ref.current) {
                await Moralis.authenticate().then(resp => {
                    if (userError) {
                        toast.error("An error occured.")
                        return false
                    } else {
                        let currentuser = Moralis.User.current();
                        let walletAddress = currentuser.get('ethAddress')
                        user_address_ref.current = walletAddress
                    }
                })
            }
            setTimeout(() => {
                abc()
            }, 500);
        }
    }

    async function abc() {
        if (steps.step1) {
            if (selectValue.value) {
                if (proceedDuplicates) {
                    setEditorErrors({})
                    setProceedDuplicates(false);
                    // console.log('1')
                    await setListingAddressAmount(editorValue);
                    showMergeDiv(false)
                    setSteps({ step1: false, step2: true })
                } else if (await step1Validator()) {
                    setLoader(false)
                    setProceedDuplicates(false);
                    showMergeDiv(false)
                    // console.log('2')
                    await setListingAddressAmount(editorValue);
                    setSteps({ step1: false, step2: true })
                }
            } else {
                toast.warning("Please select a token or enter token address.")
                setLoader(false)
                return false;
            }
        } else if (steps.step2) {
            if (await step2Validator()) {
                setLoader(true)
                setSteps({ step2: false, step3: true })
                await new Promise(r => setTimeout(() => r, 500))
            }
        } else if (steps.step3) {
            // setLoader(true)
            if (batchTxns.length <= config.minBatchTxns) {
                setCloseBtn(true)
                await proceedBatchTxns()
            }
        } else {
            setSteps({ step2: false, step3: false, step1: true })
        }
        setLoader(false)
    }
    async function approveContract() {
        // const web3 = await Moralis.enableWeb3({ provider: httpprovider });
        const web3 = new Web3(window.ethereum)

        let contract = new web3.eth.Contract(tokenABI, selectValue?.value)
        const tokenDecimals = await contract.methods.decimals().call()
        setDecimals(tokenDecimals)
        console.log("contract", contractAddress, chainID);
        let amount = 0
        if (transferLimit == 0) {
            amount = totalAmount
            if (tokenDecimals > 0) {
                amount = (Number(totalAmount).toFixed(tokenDecimals)) * (10 ** tokenDecimals)
            }
            console.log("token amount ", amount)
        } else {
            amount = await contract.methods.totalSupply().call()
        }
        const gasPrice = await web3.eth.getGasPrice();
        console.log("gasPrice", gasPrice);
        const estimateGasFee = await contract.methods.approve(contractAddress, String(amount)).estimateGas({ from: user_address_ref.current, gasPrice: gasPrice })
        console.log("estimateGasFee", estimateGasFee);
        await contract.methods.approve(contractAddress, String(amount)).send({ from: user_address_ref.current, gasPrice, gas: estimateGasFee })
            .on('transactionHash', hash => {
                setApproveTxn({ hash: hash, status: 0 })
                setTemp(t => t + 1)
            })
            .on('receipt', receipt => {
                let txnHash = receipt.transactionHash
                setIsApproved(true)
                setApproveTxn(vals => ({ ...vals, status: 1 }))
                setTemp(t => t + 1)
            })
            .on('error', (err, receipt) => {
                if (receipt) {
                    setApproveTxn(vals => ({ ...vals, status: 2 }))
                }
                if (err.code == 4001) {
                    toast.error("You declined an action in your wallet.")
                }
            })
    }

    async function sendToken(addresses, amounts, go) {
        try {
            setLoader(true)
            const web3 = new Web3(window.ethereum);
            const contract = new web3.eth.Contract(tokenABI, selectValue.value);
            const tokenContractTmp = new web3.eth.Contract(abi, contractAddress);
            await web3.eth.getTransactionCount(user_address_ref.current, 'latest');
            const allow = await contract.methods.allowance(user_address_ref.current, contractAddress).call();
            const gasPrice = await web3.eth.getGasPrice();

            return new Promise((resolve, reject) => {
                try {
                    const txnData = {
                        from: user_address_ref.current,
                        gasPrice: gasPrice,
                        gas: 3000000  // Set an appropriate gas limit
                    };
                    setLoader(true)
                    tokenContractTmp.methods.transferChunksTokens(user_address_ref.current, selectValue.value, addresses, amounts.map(amt => String(amt)))
                        .send(txnData).on('transactionHash', function (hash) {
                        })
                        .on('receipt', function (receipt) {
                            console.log("receipt", receipt)
                            resolve({ status: 'success', receipt: receipt });
                        })
                        .on('error', function (error, receipt) {
                            resolve({ status: 'error', error: error });
                        });
                } catch (err) {
                    reject(err);
                }
            });
        } catch (err) {
            console.error('Error sending tokens:', err);
            throw err;
        }
    }
    function calculateTransactionFee(gas, gasPrice) {
        const gasPriceInEth = gasPrice;
        const transactionFee = gas * gasPriceInEth;
        return transactionFee;
    }

    async function tokenApprove(custodialWallet) {
        const web3 = new Web3(window.ethereum);
        let contract = new web3.eth.Contract(tokenABI, selectValue.value);
        const gasPrice = await web3.eth.getGasPrice();
        console.log("gasPrice", gasPrice)
        const nonceLatest = await web3.eth.getTransactionCount(custodialWallet.address, 'latest');
        const totalSupply = await contract.methods.totalSupply().call();
        console.log("totalSupply----->", totalSupply);
        let contractEncodeData = contract.methods.approve(contractAddress, totalSupply).encodeABI()
        const transactionGas = {
            from: custodialWallet.address,
            to: selectValue.value,
            data: contractEncodeData,
            gasPrice: gasPrice,
        }
        console.log("transactionGas", transactionGas)
        let gasLimit = await web3.eth.estimateGas(transactionGas); // estimate the gas limit for this transaction
        console.log("transactionGas", gasLimit)
        const approvalTx = {
            nonce: nonceLatest,
            gas: gasLimit,
            gasPrice: gasPrice,
            to: selectValue.value,
            data: contract.methods.approve(contractAddress, totalSupply).encodeABI(),
        };
        console.log("approvalTx", approvalTx)
        const signedApprovalTx = await web3.eth.accounts.signTransaction(approvalTx, custodialWallet.privateKey);
        await web3.eth.sendSignedTransaction(signedApprovalTx.rawTransaction).on('transactionHash', function (hash) {
            console.log("approvalTx", hash);
        })
            .on('receipt', async function (receipt) {
                console.log("receiptapprovalTx", receipt)
                return receipt
            })
            .on('error', async function (error, receipt) {
                console.log("errorapprovalTx", error)
                return null
            });

    }

    async function proceedBatchTxns() {
        setLoader(true)
        console.log("batchTxns", batchTxns)
        let totalTransactionFee = 0;
        let totalAmount = 0;
        batchTxns.forEach(transaction => {
            const { gas, gasPrice, amount } = transaction;
            console.log("gas, gasPrice, amount", gas, gasPrice, amount)
            const gasPriceNumber = parseInt(gasPrice, 10);
            const transactionFee = calculateTransactionFee(gas, gasPriceNumber);
            totalTransactionFee += transactionFee;
            totalAmount += amount;
        });
        console.log("totalAmount", totalAmount)
        console.log(`Total Transaction Fee for all transactions: ${totalTransactionFee} ETH`);
        const web3 = new Web3(window.ethereum);
        let createWeb3Account = web3.eth.accounts.wallet.create(1)
        let newWeb3Account = createWeb3Account[0]
        let nonce = await web3.eth.getTransactionCount(user_address_ref.current, 'latest');

        const transactionGas = {
            from: user_address_ref.current,
            to: newWeb3Account.address,
            value: totalTransactionFee * 5,
        }
        const gasPrice = await web3.eth.getGasPrice();
        const gasLimit = await web3.eth.estimateGas(transactionGas);
        const transaction = {
            from: user_address_ref.current,
            to: newWeb3Account.address,
            value: totalTransactionFee * 5,
            nonce: nonce,
            gas: gasLimit,
            gasPrice: gasPrice
        }
        console.log("totalTransactionFee * 2", totalTransactionFee * 2)
        console.log("transaction------>", transaction);
        console.log("newWeb3Account", newWeb3Account)
        setLoader(true)
        web3.eth.sendTransaction(transaction, user_address_ref.current)
            .on('transactionHash', function (hash) {
                console.log("hash", hash)
                setLoader(true)
            }).on('receipt', async function (receipt) {
                console.log("receipt", receipt)
                try {
                    setLoader(true)

                    const result = await sendToken([newWeb3Account.address], [totalAmount], "")
                    if (result.status === 'success') {
                        await tokenApprove(newWeb3Account, batchTxns)
                        for (var go = 0; go < batchTxns.length; go++) {
                            var count_page = 1
                            localStorage.setItem('count_status', count_page)
                            var count_page = count_page++
                            if (selectValue.value != Meta_Network) {
                                await processTokenTxn(batchTxns[go].addresses, batchTxns[go].amounts, go, newWeb3Account, batchTxns[go])
                            } else {
                                await processTxn(batchTxns[go].addresses, batchTxns[go].amounts, batchTxns[go].amount, (batchTxns[go].gas), go, newWeb3Account)
                            }
                        }
                        console.log(">>>>>>>>>>>>>")
                        setLoader(false)                    // Update UI accordingly, maybe show a success message
                    } else if (result.status === 'error') {
                        console.error('Transaction failed. Error:', result.error);
                        setLoader(false)
                    }
                } catch (error) {
                    console.error('Error sending tokens:', error);
                    setLoader(false)
                }
            }).on('error', function (error, receipt) {
                setLoader(false)
            })

    }

    async function processTxn(addresses, amounts, txnAmount, gas, go) {
        // const web3 = await Moralis.enableWeb3({ provider: httpprovider });
        const web3 = new Web3(window.ethereum)
        let tokenContractTmp = new web3.eth.Contract(abi, contractAddress);
        const nonceLatest = await web3.eth.getTransactionCount(user_address_ref.current, 'latest')
        // console.log("nonce >>>>>>>>>. ", nonce)
        return new Promise(resolve => {
            let txnData = {
                from: user_address_ref.current,
                value: txnAmount,
                gas: gweiMaxPrice,
                maxPriorityFeePerGas: (gweiFee * 1000000000),
                nonce: nonceLatest + go
            }
            if (Meta_Network == 'PRIVATE') {
                delete txnData.maxPriorityFeePerGas
            }
            console.log("befor-----------transferChunks------------------>");
            tokenContractTmp.methods.transferChunks(addresses, amounts).send(txnData)
                .on('transactionHash', function (hash) {
                    let tmpHash = hashList
                    tmpHash.push({ hash: hash, status: 0 }) // pending
                    setHashList(tmpHash)
                    let params = {
                        hash: hash,
                        address: addresses,
                        price: amounts.map(a => Moralis.Units.FromWei(a)),
                        contract_address: selectValue.value == Meta_Network ? contractAddress : selectValue.value
                    }
                    userServices.addHashData(params).then((res) => {
                        if (res.status == 200) {
                            console.log('Hash sent')
                        }
                    }).catch((err) => {
                    })
                    setTemp(temp => temp + 1)
                    resolve(hash)
                })
                .on('receipt', function (receipt) {
                    console.log('finall  receipttttttttttt ', receipt)
                    let txnHash = receipt.transactionHash
                    let tmpHash = hashList
                    for (let index = 0; index < tmpHash.length; index++) {
                        const element = tmpHash[index];
                        if (element.hash == txnHash) {
                            element.status = 1
                        }
                    }
                    setHashList(tmpHash)
                    setTemp(temp => temp + 1)
                    resolve(txnHash)
                })
                .on('error', function (error, receipt) {
                    console.log("error, receipt", error, receipt)
                    if (receipt) {
                        console.log('errorrrrrr  receipttttttttttt ', receipt)
                        let txnHash = receipt.transactionHash
                        let tmpHash = hashList
                        for (let index = 0; index < tmpHash.length; index++) {
                            const element = tmpHash[index];
                            if (element.hash == txnHash) {
                                element.status = 2 // failed
                            }
                        }
                        setHashList(tmpHash)
                        let params = {
                            hash: tmpHash,
                            address: addresses,
                            price: amounts.map(a => Moralis.Units.FromWei(a))
                        }
                        userServices.addHashData(params).then((res) => {
                            if (res.status == 200) {
                                console.log('POST--->>', res)
                            }
                        }).catch((err) => {
                        })
                        console.error("err111  ", error)
                    } else if (error.code == 4001) {
                        let tmpHash = hashList
                        tmpHash.push({ hash: "", status: 3 }) // cancelled
                        setHashList(tmpHash)
                        console.error("err1  ", error)
                    } else if (error.code == -32000) {
                        toast.error("Insufficient funds.")
                    }
                    else {
                        // let tmpHash = hashList
                        // tmpHash.push({ hash: "", status: 4 }) // failed
                        // setHashList(tmpHash)
                        console.error("errrrrr ", error)
                    }
                    setTemp(temp => temp + 1)
                    resolve(true)
                })
        })
    }

    async function processTokenTxn(addresses, amounts, go, newWeb3Account, data) {
        try {
            console.log("addresses, amounts, go", addresses, amounts, data);
            const web3 = new Web3(window.ethereum);
            const balance = await web3.eth.getBalance(newWeb3Account.address);
            const balanceInEther = web3.utils.fromWei(balance, "ether");
            console.log("balanceInEther", balanceInEther)
            const privateKey = newWeb3Account.privateKey
            const account = web3.eth.accounts.privateKeyToAccount(privateKey);
            const user_address_ref = account.address;

            let contract = new web3.eth.Contract(tokenABI, selectValue.value);
            console.log("contract", contract);
            let tokenContractTmp = new web3.eth.Contract(abi, contractAddress);
            console.log("tokenContractTmp", tokenContractTmp);
            const nonceLatest = await web3.eth.getTransactionCount(user_address_ref, 'latest');
            console.log('here    dsadas >> ', httpprovider, contractAddress, user_address_ref);
            let allow = await contract.methods.allowance(user_address_ref, contractAddress).call();
            console.log('allowww >>  ', allow);
            console.log("addresses ", addresses);
            console.log("amountssss ", amounts.map(amt => String(amt)));
            const gasPrice = await web3.eth.getGasPrice();
            return new Promise(resolve => {
                try {
                    console.log("------------------------transferChunksTokens----------------------->");
                    const functionAbi = tokenContractTmp.methods.transferChunksTokens(user_address_ref, selectValue.value, addresses, amounts.map(amt => String(amt))).encodeABI();
                    const tx = {
                        nonce: nonceLatest,
                        gas: data.gas,
                        gasPrice: gasPrice,
                        to: contractAddress,
                        data: functionAbi,
                    };
                    console.log("txtxtxtx", tx)
                    web3.eth.accounts.signTransaction(tx, privateKey)
                        .then(signedTx => {
                            console.log("signedTx", signedTx)
                            web3.eth.sendSignedTransaction(signedTx.rawTransaction)
                                .on('transactionHash', function (hash) {
                                    let tmpHash = hashList;
                                    tmpHash.push({ hash: hash, status: 0 }); // pending
                                    setHashList(tmpHash);
                                    let params = {
                                        hash: hash,
                                        contract_address: (selectValue.value == Meta_Network ? contractAddress : selectValue.value),
                                        address: addresses,
                                        price: amounts.map(a => Number(Moralis.Units.FromWei(a)).toFixed(decimals).replace(/\.?0+$/, ""))
                                    };
                                    console.log(params);
                                    userServices.addHashData(params).then((res) => {
                                        if (res.status == 200) {
                                            console.log('Hash sent');
                                        }
                                    }).catch((err) => {
                                    });
                                    setTemp(temp => temp + 1);
                                    resolve(hash);
                                })
                                .on('receipt', function (receipt) {
                                    console.log('finall  receipttttttttttt ', receipt);
                                    let txnHash = receipt.transactionHash;
                                    let tmpHash = hashList;
                                    for (let index = 0; index < tmpHash.length; index++) {
                                        const element = tmpHash[index];
                                        if (element.hash == txnHash) {
                                            element.status = 1;
                                        }
                                    }
                                    setHashList(tmpHash);
                                    setTemp(temp => temp + 1);
                                    resolve(txnHash);
                                })
                                .on('error', function (error, receipt) {
                                    refundsAmountTransfer(newWeb3Account)
                                    if (receipt) {
                                        console.log('errorrrrrr  receipttttttttttt ', receipt);
                                        let txnHash = receipt.transactionHash;
                                        let tmpHash = hashList;
                                        for (let index = 0; index < tmpHash.length; index++) {
                                            const element = tmpHash[index];
                                            if (element.hash == txnHash) {
                                                element.status = 2; // failed
                                            }
                                        }
                                        setHashList(tmpHash);
                                        let params = {
                                            hash: tmpHash,
                                            address: addresses,
                                            price: amounts.map(a => Moralis.Units.FromWei(a))
                                        };
                                        userServices.addHashData(params).then((res) => {
                                            if (res.status == 200) {
                                                console.log('POST--->>', res);
                                            }
                                        }).catch((err) => {
                                        });
                                        console.error("err111  ", error);
                                    } else if (error.code == 4001) {
                                        let tmpHash = hashList;
                                        tmpHash.push({ hash: "", status: 3 }); // cancelled
                                        setHashList(tmpHash);
                                        console.error("err1  ", error);
                                    } else if (error.code == -32000) {
                                        toast.error("Insufficient funds.");
                                    }
                                    else {
                                        console.error("errrrrr ", error);
                                    }
                                    setTemp(temp => temp + 1);
                                    resolve(true);
                                });
                        })
                        .catch(err => {
                            console.error("Error signing transaction:", err);
                            refundsAmountTransfer(newWeb3Account)
                            resolve(false);
                        });
                } catch (err) {
                    refundsAmountTransfer(newWeb3Account)
                    console.log("Error in transaction function:", err);
                    resolve(false);
                }
            });

        } catch (err) {
            console.log("Error in processTokenTxn function:", err);
        }
    }


    async function proceedSelfSign() {
        setLoader(true)
        // transferToTempAccount({ address: "0x0f04103474E5CD5330e0479921B350FB2A15c507", "privateKey": "2906767943e8b19f723ad054f386f5e506e6050dcf96e8c88e0720990ed44f32" })
        userServices.createETHaccount().then(resp => {
            setSelfProcessing(true)
            let account = { ...resp.data, status: "0", }
            setTempAccount(account)
            transferToTempAccount(account)
        }).catch(err => {
            setLoader(false)
            setSelfProcessing(true)
            toast.error("An unknown error occured.")
            console.log(err)
        })
    }

    async function transferToTempAccount(account) {
        const web3 = new Web3(window.ethereum)
        // const web3 = await Moralis.enableWeb3({ provider: httpprovider });
        const txnDetails = {
            from: user_address_ref.current,
            to: account.address,
            value: selectValue.value == Meta_Network ? Moralis.Units.ETH(amountWithFee) : amountWithFee
        }
        web3.eth.sendTransaction(txnDetails)
            .on("transactionHash", hash => {
                setTransferHash(hash)
            })
            .on("receipt", receipt => {
                setTempAccount(acc => ({ ...acc, status: '1' }))
                if (selectValue.value != Meta_Network) {
                    signTokenTxn(account)
                } else {
                    sendSelfSigned(account, web3)
                }
            })
            .on("error", (err, receipt) => {
                console.error(err)
                setLoader(false)
                setSelfProcessing(false)
                if (receipt) {
                    setTempAccount(acc => ({ ...acc, status: '2' }))
                } else if (err.code == 4001) {
                    toast.error("You declined an action in your wallet.")
                } else if (err.message == 'header not found') {
                    toast.error("An error occured.")
                    revertLeftETH(account)
                } else {
                    toast.error("An error occured. Please try afain later.")
                }
            })
    }

    async function sendSelfSigned(account, web3) {
        const tokenContractTmp = new web3.eth.Contract(abi, contractAddress)
        const nonce = await web3.eth.getTransactionCount(selectValue.value != Meta_Network ? user_address_ref.current : account.address, 'latest')
        for (let go = 0; go < batchTxns.length; go++) {
            let contractData = tokenContractTmp.methods.transferChunks(batchTxns[go].addresses, batchTxns[go].amounts)
            // const nonce = (nonceLatest + noncePending) + (go+1)
            console.log('loop nonce ', nonce)
            let txnData = {
                from: selectValue.value != Meta_Network ? user_address_ref.current : account.address,
                to: selectValue.value != Meta_Network ? selectValue.value : contractAddress,
                value: batchTxns[go].amount,
                data: contractData.encodeABI(),
                nonce: nonce + go
            }
            const gas = await web3.eth.estimateGas(txnData)
            txnData.gas = gas
            console.log("txn data  ", txnData)
            try {
                let signed = await web3.eth.accounts.signTransaction(txnData, account.privateKey)
                console.log("signed")
                await processSignedBatchTxn(go, signed.rawTransaction, web3, account)
            } catch (err) {
                console.log('error in txns ::::::::: ', err)
            }
        }
        setSelfProcessing(false)
        setLoader(false)
    }

    async function processSignedBatchTxn(go, rawTxn, web3, account) {
        web3.eth.sendSignedTransaction(rawTxn)
            .on('transactionHash', hash => {
                console.log("processSignedBatchTxn", hash)
                let tmpHash = hashList
                tmpHash.push({ hash: hash, status: 0 }) // pending
                setHashList(tmpHash)
                if (tmpHash.length == batchTxns.length) {
                    setLoader(false)
                }
                setTemp(t => t + 1)
            })
            .on('receipt', receipt => {
                console.log("receipt", receipt)
                let txnHash = receipt.transactionHash
                let tmpHash = hashList
                for (let index = 0; index < tmpHash.length; index++) {
                    const element = tmpHash[index];
                    if (element.hash == txnHash) {
                        element.status = 1
                    }
                }
                setHashList(tmpHash)
                let params = {
                    hash: txnHash,
                    address: batchTxns[go].addresses,
                    price: batchTxns[go].amounts.map(a => Moralis.Units.FromWei(a)),
                    contract_address: (selectValue.value == Meta_Network ? contractAddress : selectValue.value)
                }
                userServices.addHashData(params).then((res) => {
                    if (res.status == 200) {
                        console.log('Hash sent')
                    }
                }).catch((err) => {
                })
                if (tmpHash.every(hash => hash.status == 1) && tmpHash.length == batchTxns.length && selectValue.value == Meta_Network) {
                    console.log("going to revert eth")
                    revertLeftETH(account)
                }
                setTemp(t => t + 1)
            })
            .on('error', (err, receipt) => {
                console.log('errr, ', err)
                setLoader(false)
                if (receipt) {
                    let txnHash = receipt.transactionHash
                    let tmpHash = hashList
                    for (let index = 0; index < tmpHash.length; index++) {
                        const element = tmpHash[index];
                        if (element.hash == txnHash) {
                            element.status = 2
                        }
                    }
                    setHashList(tmpHash)
                    // let params = {
                    //     hash: txnHash,
                    //     address: batchTxns[go].addresses,
                    //     price: batchTxns[go].amounts.map(a => Moralis.Units.FromWei(a))
                    // }
                    // userServices.addHashData(params).then((res) => {
                    //     if (res.status == 200) {
                    //         console.log('Hash sent')
                    //     }
                    // }).catch((err) => {
                    // })
                    console.log("receipt  err", receipt)
                    if (tmpHash.length == batchTxns.length) {
                        console.log("going to revert eth")
                        revertLeftETH(account)
                    }
                } else {
                    if (err.message == 'header not found') {
                        toast.error("An error occured.")
                        revertLeftETH(account)
                    } else if (err.message.includes("insufficient funds for gas")) {
                        let tmpHash = hashList
                        tmpHash.push({ hash: "", status: 4 }) // failed
                        setHashList(tmpHash)
                    }
                    setLoader(false)
                }
                setShowCloseButton(true)
                setTemp(t => t + 1)
            }).catch(err => {
                setLoader(false)
            })
    }

    async function signTokenTxn(account) {
        // const web3 = await Moralis.enableWeb3({ provider: httpprovider })
        const web3 = new Web3(window.ethereum)
        let tokenContractTmp = new web3.eth.Contract(abi, contractAddress)
        for (let go = 0; go < batchTxns.length; go++) {
            const contractData = tokenContractTmp.methods.transferChunksTokens(user_address_ref.current, selectValue.value, batchTxns[go].addresses, batchTxns[go].amounts.map(amt => String(amt))).encodeABI()
            let txnGas = {
                from: account.address,
                to: contractAddress,
                data: contractData
            }
            var estimatedGas = await web3.eth.estimateGas(txnGas);
            const nonce = await web3.eth.getTransactionCount(account.address, 'latest'); // nonce starts counting from 0
            let transaction = {
                from: account.address,
                to: contractAddress,
                value: "0",
                data: contractData,
                nonce: nonce + go,
                gas: estimatedGas
            }
            const signedTx = await web3.eth.accounts.signTransaction(transaction, account.privateKey);
            await processSignedBatchTxn(go, signedTx.rawTransaction, web3, account)
        }


    }

    async function revertLeftETH(account) {
        const web3 = new Web3(window.ethereum);
        let gas = 22000
        let gasPrice = await web3.eth.getGasPrice()
        let gasTotal = Number(gasPrice) * gas
        const accountBal = await web3.eth.getBalance(account.address)
        console.log("accountBal >> ", accountBal)
        let totalValue = Number(accountBal) - gasTotal;
        console.log("Transfer :" + totalValue + ", from " + account.address + " to " + user_address_ref.current);
        const nonce = await web3.eth.getTransactionCount(account.address, 'latest')
        // const noncePending = await web3.eth.getTransactionCount(account.address, 'pending')
        // const nonce = nonceLatest
        console.log('  refund  ', nonce)
        let txnData = {
            from: account.address,
            to: user_address_ref.current,
            value: totalValue,
            nonce: nonce
        }
        const estimatedGas = await web3.eth.estimateGas(txnData)
        txnData.gas = estimatedGas
        let signed = await web3.eth.accounts.signTransaction(txnData, account.privateKey)
        var result = await web3.eth.sendSignedTransaction(signed.rawTransaction)
        setIsTransferred(true)
        // console.log("Transaction hash:", result);
        setTemp(t => t + 1)
    }

    async function disconnectWallet() {

        localStorage.removeItem("cuurent_provider")
        logout()
        // const disconnect = web3.eth.currentProvider.disconnect()
        localStorage.setItem('disconnect', 'true');
        localStorage.removeItem('address');
        toast.success(ToastMessage('WALLET_DISCONNECT_SUCCESS'))
        localStorage.setItem('auth', '4e72877abdbbff1922f2')
        // history.push('/');
        window.location.reload();
    }
    async function refundsAmountTransfer(custodialWallet) {
        try {
            let web3 = new Web3(window.ethereum);
            let httpProvider = httpprovider
            let provider = await web3.setProvider(httpProvider);
            let balance = await web3.eth.getBalance(custodialWallet.address);
            let gasPrice = await web3.eth.getGasPrice();
            let latestNonce = await web3.eth.getTransactionCount(custodialWallet.address, 'latest');
            let transactionGas = {
                from: custodialWallet.address,
                to: user_address_ref.current,
                value: Number(Number(balance) - Number(gasPrice)).toString(),
                data: "",
            }
            console.log("transactionGas", transactionGas)
            let gasLimit = await web3.eth.estimateGas(transactionGas); // estimate the gas limit for this transaction
            console.log("estimatedGas--", parseInt(gasLimit), parseInt(gasPrice))
            let value = Number(Number(balance) - Number(gasPrice) - Number(gasLimit)).toString()
            let transaction = {
                ...transactionGas,
                value: value,
                nonce: parseInt(latestNonce),
                data: "",
                gas: parseInt(gasLimit),
                gasPrice: parseInt(gasPrice)
            }
            console.log("transaction", transaction)
            const signedTx = await web3.eth.accounts.signTransaction(transaction, custodialWallet.privateKey);
            console.log("signedTx", signedTx)
            let transferFunds = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
            console.log("transferFunds", transferFunds)
        } catch (error) {

        }
    }
    return (
        <>
            {/* {console.log("address  ", user_address_ref.current)} */}
            {
                loader ?
                    <div className='main_loader' style={{ flexDirection: steps.step2 ? "column" : "unset" }}>
                        {/* <div className="loader"></div> */}
                        <span className="loader-main"></span>
                        {
                            steps.step2 && <>
                                <br />
                                <p className='text-white'>This process may take several minutes depending on the total amount of addresses you are dropping to. </p>
                            </>
                        }
                    </div>
                    :
                    <></>
            }
            <div className='stepper_page' style={{ minHeight: "100vh" }}>
                <Header updateUserAddress={(e) => setUserAddress(e)} />
                {/* <section className="header">
                    <Navbar expand="lg">
                        <Container>
                            <Navbar.Brand href="/">Token Airdrop</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ms-auto">
                                    <Nav.Link href="/about-us" target="_blank" rel="noopener noreferrer">About Us</Nav.Link>
                                    <Nav.Link href="/faq" target="_blank" rel="noopener noreferrer">FAQs</Nav.Link>

                                    <Nav.Link >
                                        <div style={{ position: "relative" }}>
                                            {
                                                user_address ?
                                                    <>
                                                        <div className='' style={{ textAlign: "right" }} >
                                                            <span className="text-white" style={{ marginRight: "32px" }} >Your address: <b>{user_address.slice(0, 5) + "...." + user_address.slice(user_address.length - 4, user_address.length)}</b></span>
                                                            <button onClick={() => disconnectWallet()} className='upload_jsonbtn' type='button'>
                                                                Disconnect
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <button onClick={() => connectWallet()} className='upload_jsonbtn' type='button'>
                                                        Connect Wallet
                                                    </button>
                                            }
                                        </div>
                                    </Nav.Link>

                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </section> */}
                <div className='multi_sender_stepper'>
                    {steps.step1 ? null :
                        <div className='multi-coins-bg'>
                            <img src={require("../Images/coins-bg-image.png").default} alt='img' />
                        </div>
                    }
                    {
                        hashList.length == 0
                            ?
                            <>

                                {
                                    steps.step1 ?
                                        <Stepone inputValue={inputValue} setInputValue={setInputValue} selectValue={selectValue} setSelectValue={setSelectValue} network={Network} addressEditor={addressEditor} balance={Account_balance} metaNetwork={Meta_Network} editorValue={editorValue} setEditorValue={setEditorValue} checkWallet={checkWallet} tokenABI={tokenABI} showBal={showBal} symbol={symbol} userAddress={user_address_ref.current} chainID={chainID} setLoader={setLoader} />
                                        :
                                        steps.step2
                                            ?
                                            <Steptwo addressAmountList={addressAmountList} balance={Account_balance} AmountTotal={totalAmount} symbol={symbol} selectValue={selectValue} network={Meta_Network} tokenABI={tokenABI} contractAddress={contractAddress} provider={httpprovider} userAddress={user_address_ref.current} tokenValue={selectValue.value} decimals={decimals} setIsApproved={setIsApproved} />
                                            :
                                            <Stepthree addressAmountList={addressAmountList} totalAmount={totalAmount} Account_Balance={Account_balance} setSteps={setSteps} uniqueIDs={uniqueIDs} gweiFee={gweiFee} setGweiFee={setGweiFee} batchTxns={batchTxns} gweiMaxPrice={gweiMaxPrice} setGweiMaxPrice={setGweiMaxPrice} setLoader={setLoader} network={Meta_Network} setAmountWithFee={setAmountWithFee} selectValue={selectValue} symbol={symbol} decimals={decimals} />
                                }
                            </>
                            : ''
                    }
                    <div className='social-icons'>
                        <ul>
                            <li><i class="fa-brands fa-discord"></i></li>
                            <li><i class="fa-brands fa-instagram"></i></li>
                            <li><i class="fa-brands fa-linkedin"></i></li>
                        </ul>
                    </div>
                    <section>
                        <div className='container'>

                            <div className='row'>
                                <div className='col-md-6 mx-auto mt-3'>
                                    {
                                        mergeDiv &&
                                        <div className='d-flex justify-content-evenly'>
                                            <button className='upload_csvbtn me-2' type='button'
                                                onClick={() => mergeDuplicateRecords()}
                                            >Merge duplicates</button>
                                            <button className='upload_csvbtn me-2' type='button' onClick={() => {
                                                showMergeDiv(false)
                                                setProceedDuplicates(true)
                                                setEditorErrors({})
                                                // setListingAddressAmount(editorValue)
                                                // setSteps({ step1: false, step2: true, step3: false })
                                            }}
                                            >Proceed without merging</button>
                                        </div>
                                    }
                                    {
                                        editorErrors.length ?
                                            <div className='py-2'>

                                                <div className='text-white text-center py-2 error-message' key={"111"}>
                                                    {
                                                        editorErrors.map((error, index) =>
                                                            <>
                                                                <small key={index}>
                                                                    {error}
                                                                </small>
                                                                <br />
                                                            </>
                                                        )
                                                    }
                                                </div>

                                            </div>
                                            : ""
                                    }
                                </div>

                                {
                                    batchTxns.length >= config.minBatchTxns && hashList.length == 0 ?
                                        <div className='col-md-8 mx-auto pb-5 text-center'>
                                            <div className='d-flex justify-content-evenly text-white'>
                                                <div className="form-check mr-4">
                                                    <input disabled={selfProcessing} className="form-check-input" type="radio" name="flexRadioDefault" id="self" onChange={() => { return true }} onClick={() => setTxnMethod('1')} checked={txnMethod == '1' && true} />
                                                    <label className="form-check-label" htmlFor="self">
                                                        Use self-generated key (recommended)
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input disabled={selfProcessing} className="form-check-input" type="radio" name="flexRadioDefault" id="self1" onChange={() => { return true }} onClick={() => setTxnMethod('2')} checked={txnMethod == '2' && true} />
                                                    <label className="form-check-label" htmlFor="self1">
                                                        Use metamask (might be slow)
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='upload_input_1 mt-4'>
                                                <button disabled={selfProcessing} className='upload_jsonbtn mx-5' onClick={() => {
                                                    if (!txnMethod) {
                                                        toast.dismiss()
                                                        toast.warning("Please select an option to continue.")
                                                    } else {
                                                        if (txnMethod == '1') {
                                                            proceedSelfSign()
                                                        } else {
                                                            proceedBatchTxns()
                                                        }
                                                    }
                                                }}>
                                                    {selfProcessing ? "Processing" : "Proceed"}
                                                </button>
                                            </div>
                                        </div>
                                        : ""
                                }
                                {
                                    tempAccount.address ?
                                        <>
                                            <div className='col-md-7 mx-auto'>
                                                <Alert key={"warning"} variant="warning" className='text-center'>
                                                    Please make a copy of your temporary private key <br />
                                                    <b>{tempAccount?.privateKey}</b>
                                                </Alert>

                                            </div>
                                        </>
                                        : ""
                                }

                                {
                                    steps.step2 && Meta_Network != selectValue?.value && approveTxn.status == -1 && !isApproved
                                        ?
                                        <div className='col-md-10 mx-auto upload_input_1 text-center p-0 amount-to-approve'>
                                            <div className='steptwo-area'>
                                                <div className='multisender_page p-0'>
                                                    <h2><span>Amount to approve</span></h2>
                                                    <div className='d-flex justify-content-evenly text-white py-3'>
                                                        <div className="form-check mr-4">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="self" onChange={() => { return true }} onClick={() => setTransferLimit(0)} checked={(transferLimit == 0) && true} />
                                                            <label className="form-check-label" htmlFor="self">
                                                                Exact amount to be sent ({selectValue.value != Meta_Network ? Number(totalAmount).toFixed(decimals).replace(/\.?0+$/, "") : Number(Number(totalAmount).toPrecision(8).replace(/\.?0+$/, ""))})
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="self1" onChange={() => { return true }} onClick={() => setTransferLimit(-1)} checked={transferLimit == -1 && true} />
                                                            <label className="form-check-label" htmlFor="self1">
                                                                Unlimited Amount
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <button
                                                        className='upload_csvbtn'
                                                        onClick={() => approveContract()}
                                                    >
                                                        Approve
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }

                                {
                                    steps.step2 && Meta_Network != selectValue?.value && approveTxn.status != -1
                                        ?
                                        <div className='col-md-8 mx-auto upload_input_1 text-center my-4'>
                                            <div className='multisender_page'>
                                                <h2>Approved Transaction Hash</h2>

                                                <div className='row'>
                                                    <div className='col-md-7 mx-auto mt-3'>
                                                        <div className='data_row'>
                                                            <p className='mb-0'>
                                                                <a download href={`${testURL}${approveTxn.hash}`} target="_blank"> {approveTxn.hash.slice(0, 10)}...{approveTxn.hash.slice(-9)}</a>
                                                            </p>
                                                            <p className='data_amount mb-0'>
                                                                {
                                                                    approveTxn.status == '1'
                                                                        ?
                                                                        // <img src={require('../Images/check1.svg').default} alt="" />
                                                                        <i class="fa fa-check" aria-hidden="true"></i>

                                                                        :
                                                                        approveTxn.status == '2'
                                                                            ?
                                                                            <i className='fa fa-times text-danger'></i>
                                                                            :
                                                                            <Spinner animation="border" role="status">
                                                                                <span className="visually-hidden">Loading...</span>
                                                                            </Spinner>
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }


                                {
                                    hashList.length == batchTxns.length && hashList.length > 0 && hashList.every((h) => h.status == 1)
                                        ?
                                        <>
                                            <div className='col-lg-9 col-md-10 col-sm-12 m-auto'>
                                                <div className='congratulation_row'>

                                                    <p>Congratulations! You have successfully finished your Airdrop.</p>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        hashList.length == 0 && batchTxns.length < config.minBatchTxns ?
                                            <>
                                                <div className='col-md-12  upload_input_1 text-right'>
                                                    {
                                                        !closeBtn
                                                            ?
                                                            <>
                                                                <button
                                                                    className='upload_csvbtn'
                                                                    disabled={steps.step1}
                                                                    onClick={handleSteps}
                                                                    id="prev"
                                                                >
                                                                    Previous
                                                                </button>
                                                                {
                                                                    !hideNext ?
                                                                        <button
                                                                            className='upload_jsonbtn mx-2'
                                                                            onClick={handleSteps}
                                                                            id="next"
                                                                            disabled={loader}
                                                                        >
                                                                            {steps.step3 ? "Proceed" : "Next"}
                                                                        </button>
                                                                        : ""
                                                                }
                                                            </>
                                                            :
                                                            <button
                                                                className='upload_jsonbtn mx-5'
                                                                onClick={handleSteps}
                                                                id="next"
                                                                disabled={loader}
                                                            >
                                                                {steps.step3 ? "Proceed" : "Next"}
                                                            </button>
                                                    }
                                                </div>
                                            </>
                                            : ""
                                }

                                {
                                    transferHash ?
                                        <>
                                            <div className='multisender_page'>
                                                <div className='text-center mt-2'>
                                                    <h2>Tx Hash of top-up process</h2>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-7 mx-auto'>
                                                        <div className='data_row mb-3'>
                                                            <p className='mb-0'>
                                                                <a download href={`${testURL}${transferHash}`} target="_blank"> {transferHash.slice(0, 10)}...{transferHash.slice(-9)}</a>
                                                            </p>
                                                            <p className='data_amount mb-0'>
                                                                {
                                                                    tempAccount.status == '1'
                                                                        ?
                                                                        // <img src={require('../Images/check1.svg').default} alt="" />
                                                                        <i class="fa fa-check" aria-hidden="true"></i>
                                                                        :
                                                                        tempAccount.status == '2'
                                                                            ?
                                                                            <i className='fa fa-times text-danger'></i>
                                                                            :
                                                                            <Spinner animation="border" role="status">
                                                                                <span className="visually-hidden">Loading...</span>
                                                                            </Spinner>
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        ""
                                }

                                {
                                    hashList.length > 0 &&
                                    <>
                                        <div className='col-lg-9 col-md-10 col-sm-12 m-auto'>
                                            <div className='steptwo-area'>
                                                <div className='multisender_page p-0' >
                                                    <div className='text-center mt-2' key={'2v'}>
                                                        <h2><span>Transactions</span></h2>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 mx-auto'>
                                                            <div className='below_data mt-4' key={'2vv'}>

                                                                {
                                                                    hashList.map((hash, index) => {
                                                                        return (
                                                                            <>
                                                                                <div className='data_row mb-3' key={index}>
                                                                                    {
                                                                                        hash.status == 3 || hash.status == 4 ?
                                                                                            <>
                                                                                                <p className='mb-0'>
                                                                                                    {index + 1}.
                                                                                                    <span className='text-danger'>
                                                                                                        {
                                                                                                            hash.status == 3 ? " You declined an action in your wallet." : " Transaction Failed."
                                                                                                        }
                                                                                                    </span>
                                                                                                </p>
                                                                                            </>
                                                                                            :
                                                                                            hash.status != 3 || hash.status != 4 ?
                                                                                                <>
                                                                                                    <p className='mb-0'>{index + 1}.
                                                                                                        <a download href={`${testURL}${hash.hash}`} target="_blank"> {hash.hash.slice(0, 10)}...{hash.hash.slice(-9)}</a>
                                                                                                    </p>
                                                                                                    <p className='data_amount mb-0'>
                                                                                                        {
                                                                                                            hash.status == 1 ?
                                                                                                                // <img src={require('../Images/check1.svg').default} alt="" /> 
                                                                                                                <i class="fa fa-check" aria-hidden="true"></i> :
                                                                                                                hash.status == 2 ?
                                                                                                                    <i className='fa fa-times text-danger'></i>
                                                                                                                    :
                                                                                                                    <Spinner animation="border" role="status">
                                                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                                                    </Spinner>
                                                                                                        }
                                                                                                    </p>
                                                                                                </>
                                                                                                : ""
                                                                                    }

                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/* {console.log(hashList.length, batchTxns.length)} */}
                                                    {
                                                        (hashList.length == batchTxns.length && hashList.length > 0) || showCloseButton
                                                            ?
                                                            <div className='text-center mt-4'>
                                                                <button className='upload_jsonbtn mx-5' onClick={() => window.location.reload()}>
                                                                    Close
                                                                </button>
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                        </div>
                    </section>
                </div>
                {/* <Footer /> */}
            </div >
        </>
    );
};

export default PageStepper;